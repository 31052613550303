import React, { useEffect , useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Input from "@mui/material/Input";
import { TextareaAutosize } from '@mui/base';
import Stack from "@mui/material/Stack";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';

export default function NewTemplate(props) {
  const navigate = useNavigate();
  const { userId, setRestart, restart, name, id, TempTitle, TempDescription , categoryList , categories } =
    props;
  const API_BASEURL = process.env.API_BASEURL
    ? process.env.API_BASEURL
    : "http://localhost:3001";

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 850,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const [buttonTitle, setButtonTitle] = useState(
    name ? name : "New Template"
  );
  const [category, setCategory] = useState(categoryList && categoryList.length > 0 ? categoryList : []);

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const [allCategories, setAllCategories] = useState(categories);
  const [open, setOpen] = useState(false);
  const [description, setDescription] = useState(
    TempDescription ? TempDescription : ""
  );
  const [title, setTitle] = useState(TempTitle ? TempTitle : "");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  
  useEffect(()=>{
    setTitle(TempTitle ? TempTitle : "")
    setDescription(TempDescription ? TempDescription : "")
    setCategory(categoryList ? categoryList : [])
  },[TempTitle,TempDescription,categoryList])

  useEffect(()=>{
     setAllCategories(categories)
  },[categories])
  // useEffect(()=>{
  //     getAllCategories();   
  // },[])

  
  // const getAllCategories = () => {
  //   var myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  //   var requestOptions = {
  //     method: "GET",
  //     headers: myHeaders,
  //     redirect: "follow",
  //   };

  //   fetch(`/api/category`, requestOptions)
  //     .then((response) => response.json())
  //     .then((result) => {
  //       if (result.status) {
  //         setAllCategories(result.categories)
  //       } else {
  //         toast.error(result?.message);
  //       }
  //     })
  //     .catch((error) => console.log("error", error));
  // }

  const handleSave = () => {
    if (title == "" || description == "" || category.length == 0) {
      toast.error("All fields are required!");
      return false;
    }

    if (id) {
      updateTemplate();
    } else {
      createTemplate();
    }
  };
  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };
  const createTemplate = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("name", title);
    urlencoded.append("description", description);
    urlencoded.append("category", JSON.stringify(category));
    urlencoded.append("user_id", userId);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    fetch(`/api/prompt_template`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          toast.success("Template Created Successfully");
          setTitle("");
          setDescription("");
          setCategory([]);
          navigate(`/view-template/${result?.id}`)
          // setOpen(false);
          // setRestart(!restart);
        } else {
          toast.error(result?.message);
        }
      })
      .catch((error) => console.log("error", error));
  };
  
  const handleChange = (event) => {
    if (event.target.value.length <= 20) {
      setDescription(event.target.value);
    }
  };
  const updateTemplate = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("name", title);
    urlencoded.append("description", description);
    urlencoded.append("category", JSON.stringify(category));
    urlencoded.append("user_id", userId);

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    fetch(`/api/prompt_template/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          toast.success("Template Updated Successfully");
          setOpen(false);
          setRestart(!restart);
        } else {
          toast.error(result?.message);
        }
      })
      .catch((error) => console.log("error", error));
  };
  return (
    <>
      <div className="">
        <Button className={id && 'update_prompt_temp'} onClick={handleOpen}>{buttonTitle}</Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h4"
            component="h2"
            className={`modal_head ${id ? 'update_prompt_temp' : ''}`}
          >
            {id ? 'Edit Template' : 'New Template'}
          </Typography>

            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <div className="label_input">
                <Typography component="label">Name</Typography>
                <Input  
                  onChange={handleTitleChange}
                  value={title}                 
                  placeholder="Template name"
                />
              </div>
              <div className="label_input">
                <Typography component="label">Category</Typography>
                <Select
                  className="cats-select"
                    multiple
                    value={category}
                    displayEmpty 
                    placeholder="Select a Category"
                    label="Select a Category"
                    onChange={handleCategoryChange}
                    renderValue={(selected) => (
                      <div style={{ display: 'flex', maxWidth: '100%' }}>
                        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                          {selected.length > 0 ? selected.map((value, index) => (
                            <React.Fragment key={value}>
                              {index > 0 && ', '}
                              {allCategories.find((category) => category._id === value)?.name}
                            </React.Fragment>
                          )) : 'Select a Category'}
                        </div>
                      </div>
                    )}
                    style={{ maxWidth: '30%' }}
                  >
                  <MenuItem value={[]}>
                    <ListItemText primary='Select a category' />
                  </MenuItem>
                  {allCategories.map((categoryItem) => (
                    <MenuItem key={categoryItem._id} value={categoryItem._id}>
                      <Checkbox checked={category.includes(categoryItem._id)} />
                      <ListItemText primary={categoryItem.name} />
                    </MenuItem>
                  ))}
                </Select>

              </div>
              <div className="label_input">
                <Typography component="label">Description</Typography>
                <TextareaAutosize
                  placeholder="Template Description"
                  value={description}
                  onChange={handleDescriptionChange}
                />
              </div>
              <div className="flow_delete_btn">
                <Stack direction="row" spacing={2}>
                  <Button onClick={handleClose} variant="contained">
                    Close
                  </Button>
                    <Button
                      variant="contained"
                      onClick={handleSave}
                    >
                      {id ? 'Update' : 'Create'}
                    </Button>
                </Stack>
              </div>
            </Typography>
          </Box>
        </Modal>
      </div>
    </>
  );
}
