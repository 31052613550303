import React, { useEffect, useState } from "react";
import SidebarLayout from "./layouts/SidebarLayout";
import { styled, alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
// import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
// import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import moment from "moment";

import NewTemplate from "../components/NewTemplate";
import PromptBox from "../components/PromptBox";
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { InputLabel } from '@mui/material';
import { useAuth0 } from "@auth0/auth0-react";
import { CircularProgress } from "@mui/material";
import { Tabs, Tab } from "@mui/material";
import { TabContext } from "@mui/lab";
import { TabPanel } from "@mui/lab";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import TablePagination from '@mui/material/TablePagination';

export default function PromptsPage() {
  const navigate = useNavigate();
  const [allPromptData, setAllPromptData] = useState([]);
  const [promptData, setPromptData] = useState([]);
  const [restart, setRestart] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchField, setSearchField] = useState("");
  const [systemPrompt, setSystemPrompt] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [allCategories, setAllCategories] = useState([]);
  const [pageNumber, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(9);
  const [limit, setLimit] = useState(9);
  const { category } = useParams();
  const [value, setValue] = useState(1);
  const [age, setAge] = React.useState(category);


  const [totalRecords, setTotalRecords] = useState(0)
  const [totalPages, setTotalPages] = useState(0)

  const handleCategoryChange = (event) => {
    // console.log('event', event.target.value);
    setAge(event.target.value);
    navigate(`/prompts/${event.target.value}`);
    setPage(0);
    setRowsPerPage(9);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setPage(0);
    setRowsPerPage(9);
  };




  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  }));

  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "200ch",
        "&:focus": {
          width: "200ch",
        },
      },
    },
  }));

  const { user } = useAuth0();

  const [userId, setUserId] = useState("");


  const getAllCategories = () => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`/api/category`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          setAllCategories(result.categories)
        }
      })
      .catch((error) => console.log("error", error));
  }

  useEffect(() => {
    if (user) {
      getAllCategories();
      // fetchAllPromptTemplates();
    }
  }, [user, restart, value, category]);
  useEffect(() => {
    if (user) {
      fetchAllPromptTemplates();
    }
  }, [user, restart, value, category,pageNumber,rowsPerPage,searchField]);
  


  // const handleSearch = (event) => {
  //   console.log("working....")
  //   // console.log("event", event.target.value);
  //   let query = event.target.value;
  //   // let data = value ? value : allPromptData
  //   // // console.log('PromptData', data)
  //   // const lowerCaseQuery = query.toLowerCase();
  //   // // console.log('data', data)
  //   // const filtered = data.filter(
  //   //   (item) =>
  //   //     item.title.toLowerCase().includes(lowerCaseQuery) ||
  //   //     item.description.toLowerCase().includes(lowerCaseQuery)
  //   // );
  //   // // console.log('filtered', filtered)
  //   // setPromptData(filtered);
  //   setSearchField(query);
  //   return false;
  // };

  const handleSearch = (event) => {
    let query = event.target.value;
    console.log(query)
    setSearchField(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    // props.setIsLoading(true)
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchAllPromptTemplates = async () => {
    try {
      let { sub } = user;
      setUserId(sub);
      var myHeaders = new Headers();
      myHeaders.append("user_id", sub);
      // Type if for tab (all,my,system)
      myHeaders.append("type", value);
      myHeaders.append("category", category);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      setIsLoading(true);
      await fetch(`/api/get_prompt_template?page=${pageNumber}&limit=${rowsPerPage}&search=${searchField}`, requestOptions)
        .then((response) => response.json())
        .then(async (result) => {
          if (result.status) {
            let limit = result.limit;
            // console.log(result,"hui,,,,,,,")
            setTotalRecords(parseInt(result.totalRecord))
            setPage(parseInt(result.pageNumber))
            setLimit(parseInt(limit))
            setTotalPages(result.totalPages)
            setSystemPrompt(false);
            if (result?.system_prompt) {
              setSystemPrompt(true);
            }
            let tempData = [];
            if (result.templates.length > 0) {
              await Promise.all(
                result.templates.map((val) => {
                  let cat = [];
                  val?.category.map((cate) => {
                    cat.push(cate?._id);
                  })
                  let tempObj = {
                    id: val?._id,
                    title: val?.name,
                    description: val?.description,
                    date: moment(val?.created_at).format("DD/MM/YYYY"),
                    system_template: val?.system_template,
                    category: cat,
                    totalShares: val?.totalShares,
                    templateData: val?.templateData,
                    ownerName: val?.ownerName,
                    message: val?.message
                  };
                  tempData.push(tempObj);
                })
              );
            }
            setAllPromptData(tempData);
            setPromptData(tempData);
            setLoading(false);
            // if (searchField !== '') {
            //   setLoading(true);
            //   handleSearch({ 'target': { 'value': searchField } }, tempData)
            // }
          }
          setIsLoading(false);
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log("err", error);
    }
  };
  return (
    <div>
      <div className="chatflows_page">
        <div className="chat_head">
          <Typography variant="h1">Prompt Templates</Typography>
        </div>
        {/* end heading */}
        <div className="search_box">

          <div className="search_feild">
            <Search className="serachBar">
              <SearchIconWrapper>
                <SearchIcon style={{ marginTop: '0px' }} />
              </SearchIconWrapper>
              {/* <StyledInputBase
                value={searchField}
                onChange={((event)=>handleSearch(event))}
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
                autoFocus
              /> */}
              <StyledInputBase
                  value={searchField}
                  onChange={handleSearch}
                  placeholder="Search…"
                  inputProps={{ "aria-label": "search" }}
                  autoFocus
                />
            </Search>
          </div>
          <InputLabel className="cat-heads" htmlFor="category-label">Category:</InputLabel>
          <FormControl sx={{ display: 'flex', alignItems: 'center' }}>

            <Select
              value={age}
              onChange={handleCategoryChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Category' }}
              className="cat-select"
            >
              <MenuItem value="all">
                <em>All</em>
              </MenuItem>
              {allCategories &&
                allCategories.map((val) => {
                  return (
                    <MenuItem key={val?._id} value={val?._id}>
                      {val?.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>

          <div className="flow_delete_btn">
            <NewTemplate
              categories={allCategories}
              setRestart={setRestart}
              restart={restart}
              userId={userId}
            />
          </div>
        </div>
        <TabContext value={value}>

          <div className="tab">
            <Box>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  label="All"
                  value={1}
                  className={`tab ${value === 1 ? 'activerowsPerPage-tab' : ''}`}
                />
                <Tab
                  label="My"
                  value={2}
                  className={`tab ${value === 2 ? 'active-tab' : ''}`}
                />
                <Tab
                  label="Shared"
                  value={3}
                  className={`tab ${value === 3 ? 'active-tab' : ''}`}
                />
                 <Tab
                  label="System"
                  value={4}
                  className={`tab ${value === 3 ? 'active-tab' : ''}`}
                />
              </Tabs>
              
            </Box>
          </div>
          <div className="tabBorder">
          </div>
          <TabPanel value={1}>
            {/* end search box */}
            <Box sx={{ flexGrow: 1 }}>
              {isLoading ? (
                <div style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  minHeight: '100vh',
                }}>
                  <CircularProgress />
                </div>
              ) : (
                <div className="grid-box">
                  <Grid container spacing={2}>
                    {promptData.map((data, i) => (
                      <PromptBox
                        categories={allCategories}
                        category={data.category}
                        system_template={data.system_template}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        key={data.id}
                        id={data.id}
                        title={data.title}
                        description={data.description}
                        date={data.date}
                        ownerName={data.ownerName}
                        setRestart={setRestart}
                        restart={restart}
                        userId={userId}
                        totalShares={data.totalShares}
                        tabId={value}
                        displayCopy={{isDisplay:true}}
                        message={data.message}
isSystem={data.system_template}
                      />
                    ))}
                  </Grid>
                </div>
              )}
            </Box>
          </TabPanel>
          <TabPanel value={2}>
            {/* end search box */}
            <Box sx={{ flexGrow: 1 }}>
              {isLoading ? (
                <div style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  minHeight: '100vh',
                }}>
                  <CircularProgress />
                </div>
              ) : (
                <div className="grid-box">
                  <Grid container spacing={2}>
                    {promptData.map((data, i) => (
                      <PromptBox
                        categories={allCategories}
                        category={data.category}
                        system_template={data.system_template}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        key={data.id}
                        id={data.id}
                        title={data.title}
                        description={data.description}
                        date={data.date}
                        setRestart={setRestart}
                        restart={restart}
                        userId={userId}
                        tabId={value}
                        totalShares={data.totalShares}
                        ownerName={data.ownerName}
                      />
                    ))}
                  </Grid>
                </div>
              )}
            </Box>
          </TabPanel>
          <TabPanel value={3}>
            {/* end search box */}
            <Box sx={{ flexGrow: 1 }}>
              {isLoading ? (
                <div style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  minHeight: '100vh',
                }}>
                  <CircularProgress />
                </div>
              ) : (
                <div className="grid-box">
                  <Grid container spacing={2}>
                    {promptData.map((data, i) => (
                      <PromptBox
                        categories={allCategories}
                        category={data.category}
                        system_template={data.system_template}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        key={data.id}
                        id={data.id}
                        title={data.title}
                        description={data.description}
                        date={data.date}
                        setRestart={setRestart}
                        restart={restart}
                        userId={userId}
                        ownerName={data.ownerName}
                        displayCopy={{isDisplay:true}}
                        message={data.message}
                      />
                    ))}
                  </Grid>
                </div>
              )}
            </Box>
          </TabPanel>
          <TabPanel value={4}>
            {/* end search box */}
            <Box sx={{ flexGrow: 1 }}>
              {isLoading ? (
                <div style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  minHeight: '100vh',
                }}>
                  <CircularProgress />
                </div>
              ) : (
                <div className="grid-box">
                  <Grid container spacing={2}>
                    {promptData.map((data, i) => (
                      <PromptBox
                        categories={allCategories}
                        category={data.category}
                        system_template={data.system_template}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        key={data.id}
                        id={data.id}
                        title={data.title}
                        description={data.description}
                        date={data.date}
                        setRestart={setRestart}
                        restart={restart}
                        userId={userId}
isSystem={data.system_template}
                        ownerName={data.ownerName}
                      />
                    ))}
                  </Grid>
                </div>
              )}
            </Box>
          </TabPanel>
        </TabContext>
        {!loading && allPromptData.length == 0 && !systemPrompt && (
          <div className="create_template_btn">
            <NewTemplate
              categories={allCategories}
              name="Create your first prompt template"
              setRestart={setRestart}
              restart={restart}
              userId={userId}
            />
          </div>
        )}
        {/* end chat box */}
        {/* <TablePagination
          component="div"
          count={100}
          page={pageNumber}
          // onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          // onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
       {totalPages> 1 && 
       <TablePagination
         rowsPerPageOptions={[9,25,50,100]}
          component="div"
          id="templates-pagination"
          // count={totalRecords?totalRecords:10}
          count={totalRecords}
          page={pageNumber}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        }
      </div>
    </div>
  );
}
