import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, Typography, Box, Chip } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';

const Dropzone = ({ onDrop, onRemove }) => {
  const [file, setFile] = useState(null);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      // const supportedExtensions = ['.csv', '.txt', '.html', '.pdf', '.doc', '.docx', '.json', '.jpeg', '.jpg', '.png', '.xlsx', '.pptx'];
      const supportedExtensions = ['.txt', '.html', '.pdf', '.doc', '.docx', '.json', '.pptx'];

      // Filter supported files
      const supportedFiles = acceptedFiles.filter(file =>
        supportedExtensions.some(ext => file.name.toLowerCase().endsWith(ext))
      );

      // Show error for unsupported files
      const unsupportedFiles = acceptedFiles.filter(file =>
        !supportedExtensions.some(ext => file.name.toLowerCase().endsWith(ext))
      );

      if (unsupportedFiles.length > 0) {
        toast.error(`Unsupported file types: ${unsupportedFiles.map(file => file.name).join(', ')}`);
      }

      // Set the first supported file
      if (supportedFiles.length > 0) {
        const newFile = supportedFiles[0];
        setFile(newFile);
        onDrop(supportedFiles); // Notify the parent component
      }
    },
    // accept: '.csv, .txt, .html, .pdf, .doc, .docx, .json, .jpeg, .jpg, .png, .xlsx, .pptx',
    accept: '.txt, .html, .pdf, .doc, .docx, .json, .pptx',
  });

  const handleRemoveFile = () => {
    setFile(null);
    onRemove(); // Notify the parent component that the file has been removed
  };

  return (
    <div
      {...getRootProps()}
      style={{
        border: '2px dashed #ccc',
        padding: '20px',
        cursor: 'pointer',
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '20px',
        minWidth: '555px',
        backgroundColor: '#f9f9f9',
      }}
    >
      <Typography
        variant="subtitle1"
        color="textSecondary"
        style={{
          marginBottom: '10px',
          textAlign: 'center',
          fontWeight: 'bold',
          fontSize: '14px',
        }}
      >
        Supported Format: PDF, Text, JSON, DOC, DOCX, PPTX, HTML
      </Typography>

      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <UploadFileIcon style={{ fontSize: '50px', color: '#007bff', marginRight: '10px' }} />
          <Typography variant="body2" color="textSecondary" style={{ textAlign: 'center' }}>
            {!file
              ? 'Drag and drop a file here, or click to select a file'
              : `File selected: ${file.name} (${file.size} bytes)`
            }
          </Typography>
        </div>

        {file && (
          <Box
            style={{
              width: '100%',
              maxHeight: '150px',
              overflowY: 'auto',
              marginBottom: '10px',
            }}
          >
            <Chip
              label={`${file.name} (${file.size} bytes)`}
              onDelete={handleRemoveFile}
              deleteIcon={<CloseIcon />}
              style={{ margin: '4px' }}
            />
          </Box>
        )}

        <Button variant="contained" component="span" style={{ marginTop: '10px' }}>
          Browse Files
          <input {...getInputProps()} style={{ display: 'none' }} />
        </Button>
      </Box>
    </div>
  );
};

export default Dropzone;
