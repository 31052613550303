import React from 'react'
import SidebarLayout from './layouts/SidebarLayout'
import Box from '@mui/material/Box';    
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
 
export default function FlowDetail() {

    return (


            <div className='chat_flows'>
                <div className='chat_haed'>
                    <Typography variant="h1" >
                    Flow Details
            </Typography>
                </div>
                <div className="form_feilds">
                    <Box>
                        <Typography>
                            <div className='chat_label_input'>
                                <Typography  component="label">Flow Name</Typography>
                                 <Typography  component="p">Q1/2023 Financial Analysis</Typography>
                              

                            </div>
                            <div className='chat_label_input'>
                                <Typography  component="label">Created</Typography>
                                 <Typography  component="p">01/01/2023</Typography>
                            </div>
                            <div className='chat_label_input'>
                                <Typography  component="label">Prompt Template</Typography>
                                 <Typography  component="p">Quarterly Financial Analysis</Typography>
                            </div>
                           
                            <div className='chat_label_input'>
                                <Typography  component="label">Flow Data</Typography>
                                <div className='para_box'>
                                 <Typography  component="p">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to mak</Typography>
                                </div>
                                <button id="expandButton">Expand / Collapse</button>
                                
                            </div>
                            <div className='chat_label_input'>
                                <Typography  component="label">Flow Status</Typography>
                                 <Typography  component="p">Pending</Typography>
                            </div>
                            <div className='chat_flow_delete_btn'>
                                <Stack direction="row" spacing={2}>
                                    <Button variant="contained" disabled>
                                        Cancel
                                    </Button>
                                    <Button variant="contained" href="#contained-buttons">
                                        Save
                                    </Button>
                                </Stack>
                            </div>
                        </Typography>
                    </Box>
                </div>
            </div>
    )
}
