import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Input from '@mui/material/Input';
import { TextareaAutosize } from '@mui/base';
import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import { toast } from 'react-toastify';

// import AddCircleIcon from '@mui/icons-material/AddCircle';
export default function EditModal(props) {
  const { prompt,getPromptTemplate, isOpen, setEditModel } = props;
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 850,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  useEffect(()=>{
    setOpen(isOpen)
  },[])

  const [open, setOpen] = React.useState(false);
  const [id, setId] = React.useState(prompt?.id);
  const [name, setName] = React.useState(prompt?.name);
  const [outputLength, setOutputLength] = React.useState(prompt?.outputLength);
  const [Modelprompt, setModelPrompt] = React.useState(prompt?.prompt);

  const [TextModelprompt, setTextModelPrompt] = React.useState(prompt?.prompt);
  const { promptId } = useParams();

  const handleSave = async () => {
    if (name.trim() === '' || TextModelprompt.trim() === '') {
      toast.error('All fields are required!');
      return false;
    }
    const pathSegments = window.location.pathname.split('/');
    const promptId = pathSegments[pathSegments.length - 1];

    try {
      const response = await fetch('/api/updatePrompts', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          templateId: promptId,
          promptId: props?.result?.promptId,
          prompts: [
            {
              id: props?.prompt?.id,
              name,
              prompt: TextModelprompt,
              outputLength,
            },
          ],
        }),
      });

      if (response.ok) {
        const data = await response.json();
        getPromptTemplate();
        const updatedPrompt = data.updatedData.Prompts.find(
          (p) => p.id === props.prompt.id
        );

        setName(updatedPrompt.name);
        setOutputLength(updatedPrompt.outputLength);
        setTextModelPrompt(updatedPrompt.prompt);

        toast.success(data.message);
        setOpen(false);
       setEditModel({})

      } else {
        const errorData = await response.json();
        toast.error(errorData.message);
      }
    } catch (error) {
      console.error('Error saving prompt:', error);
      toast.error('Error saving prompt. Please try again.');
    }
  };

  const handleOpen = () => setOpen(isOpen);

  const handleClose = () => {
    setName(prompt?.name);
    setOutputLength(prompt?.outputLength);
    setTextModelPrompt(prompt?.prompt);
    setOpen(false);
    setEditModel({})
  };

  return (
    <>
      <div className=" create_template2">
        {/* <Button onClick={handleOpen}>Edit</Button> */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              class="modal_head"
              variant="h4"
              component="h2"
            >
              Edit Prompt
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <div className="label_input">
                <Typography component="label">Name</Typography>
                <Input
                  placeholder="Enter the name"
                  value={name}
                  onChange={(event) => {
                    setName(event.target.value);
                  }}
                />
              </div>
              <div className="label_input">
                <Typography component="label">Output Length</Typography>
                <div className="language_slider">
                  <div className="slider_box">
                    <Box sx={{ width: 300 }}>
                      <Slider
                        aria-label="Custom marks"
                        step={100}
                        min={100}
                        max={8000}
                        value={outputLength}
                        onChange={(event) => {
                          setOutputLength(event.target.value);
                        }}
                        valueLabelDisplay="auto"
                      />
                    </Box>
                  </div>
                </div>
              </div>
              <div className="label_input">
                <Typography component="label">Prompt</Typography>
                <TextareaAutosize
                  value={TextModelprompt}
                  onChange={(event) => {
                    setTextModelPrompt(event.target.value);
                  }}
                />
              </div>
              <div className="flow_delete_btn">
                <Stack direction="row" spacing={2}>
                  <Button variant="contained" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button variant="contained" onClick={handleSave}>
                    Save
                  </Button>
                </Stack>
              </div>
            </Typography>
          </Box>
        </Modal>
      </div>
    </>
  );
}
