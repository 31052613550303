import React, { useEffect, useState } from 'react';
import {
  MenuItem,
  FormControl,
  Select,
  Box,
  Slider,
  Button,
  Stack,
} from '@mui/material/';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuth0 } from '@auth0/auth0-react';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CustomUserInputModal from '../components/CustomUserInputModal';
import Swal from 'sweetalert2';
import { TextareaAutosize } from '@mui/base';
import EditSystemPromptModal from '../components/EditSystemPromptModal';
import TestModal from '../components/TestModal';
import EditModal from '../components/EditModal';
import NewpromtModal from '../components/NewpromtModal';
import DeleteModal from '../components/DeleteModal';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

export default function ViewTemplate(props) {
  const navigate = useNavigate();
  const { user } = useAuth0();
  const { id } = useParams();
  const { select } = props;
  const [reGenerate, setReGenerate] = useState(false);
  const handleRegenerate = () => {
    setReGenerate(true);
  };
  const [promptData, setPromptData] = useState({
    name: '',
    description: '',
    languageModel: '',
    temperature: '0',
    systemPrompt: null,
    prompts: [],
    userFields: [],
    _id: '',
    promptId: '',
    promptTemplateId: id,
  });
  console.log(promptData);
  useEffect(() => {
    if (id && user) {
      console.log('first');
      const getPromptTemplate = () => {
        try {
          let { sub } = user;
          var myHeaders = new Headers();
          myHeaders.append('Content-Type', 'application/json');
          myHeaders.append('user_id', sub);
          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
          };

          fetch(`/api/prompt/${id}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
              if (result?.status) {
                let newPromptData = result?.promptData;
                newPromptData.promptTemplateId = id;

                setPromptData({
                  // ...promptData, // Spread the existing promptData
                  ...newPromptData, // Spread the new promptData received from API
                });
              } else {
                toast.error(result?.message);
                setTimeout(() => {
                  navigate('/prompts/all');
                }, 1500);
              }
            })
            .catch((error) => console.log('error', error));
        } catch (error) {
          console.log('err', error);
        } finally {
          setReGenerate(false);
        }
      };
      getPromptTemplate();
    }
  }, [id, user, navigate, reGenerate]);

  // Handle save
  const handleSave = (type, updatedData) => {
    console.log({ type });
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    let raw = JSON.stringify(
      updatedData !== undefined ? updatedData : promptData
    );

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    fetch('/api/prompt', requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // setResultIds(result.ids);
        if (result.status) {
          const { status, message, ids } = result; // Destructure the response object to get IDs
          if (type == 'user_field') {
            toast.success('User Field Created');
            // setUserFieldId(ids[0]);
            // getPromptTemplate();
            return true;
          } else if (type == 'user_field_update') {
            toast.success('User Field Updated');
            // getPromptTemplate();
            return true;
          } else if (type == 'user_field_delete') {
            toast.success('User Field Deleted');
            return true;
          } else if (type == 'prompt_delete') {
            toast.success('Prompt Deleted');
            return true;
          } else if (type == 'Prompt added') {
            toast.success('Prompt created');
            handleRegenerate();
          } else {
            toast.success('Prompt Template updated');
            setTimeout(() => {
              navigate('/prompts/all');
            }, 2000);
            // handleTestModalOpen(result.ids);
          }
        }
      })

      .catch((error) => console.log('error', error));
  };

  // userfields
  const [openFieldModal, setFieldModal] = useState(false);
  const [fieldObject, setFieldObject] = useState('');
  const handleRmeoveField = (event, id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7066e0',
      cancelButtonColor: 'grey',
      confirmButtonText: 'Yes, remove it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        let field = promptData.userFields;
        const newArray = field.filter((item) => item.id !== id);
        console.log({ field, newArray });
        if (newArray.length === 0) {
          toast.error('At least one user field is required!');
        } else {
          console.log({ newArray });
          setPromptData({
            ...promptData,
            userFields: newArray,
          });
          var raw = JSON.stringify({
            id,
            promptTemplateId: promptData.promptTemplateId,
          });
          var myHeaders = new Headers();
          myHeaders.append('Content-Type', 'application/json');

          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow',
          };

          fetch('/api/delete-user-fields', requestOptions)
            .then((response) => response.json())
            .then((result) => {
              if (result.status) {
                toast.success('User Field Deleted');
                handleRegenerate();
              }
            })
            .catch((error) => {
              console.log('error');
              toast.success(error.message || 'Internal server error');
            });
        }
      }
    });
  };
  function findMaxId(objects) {
    let maxId = -Infinity;
    objects.forEach((obj) => {
      if (obj.id > maxId) {
        maxId = obj.id;
      }
    });
    return maxId;
  }

  const handleUserField = (event, type) => {
    let fields = promptData?.userFields || [];
    if (type !== 'create') {
      for (let i = 0; i < fields.length; i++) {
        if (fields[i].id === event?.id) {
          // Replace the object
          fields[i] = event;
          break;
        }
      }
      const data = {
        ...promptData,
        userFields: fields,
      };
      if (promptData.prompts && promptData.prompts.length > 0) {
        const newPrompt = data.prompts.map((prompt) => {
          return {
            ...prompt,
            userFields: prompt?.userFields.map((field) => {
              console.log(field.id === event?.id, field.id, event?.id);
              if (field.id === event?.id) {
                return {
                  ...field,
                  name: event.name,
                  type: event.type,
                  placeholder: event.instruction,
                };
              }
              return field;
            }),
          };
        });

        // setPromptData({
        //   ...promptData,
        //   prompts: newPrompt,
        //   userFields: fields,
        // });
        // handleSave('user_field_update');
        setPromptData((prevPromptData) => {
          const updatedData = {
            ...promptData,
            prompts: newPrompt,
            userFields: fields,
          };

          handleSave('user_field_update', updatedData);
          return updatedData;
        });
      } else {
        setPromptData({
          ...promptData,
          userFields: fields,
        });
      }
    } else {
      var myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      let newId = fields.length > 0 ? findMaxId(fields) + 1 : 1;
      console.log({ newId });
      event.id = newId;

      const updatedUserFields = [event];
      const userFields = [...(promptData?.userFields || []), event];

      const data = { updatedUserFields, templateId: id };
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(data), // Make sure to stringify the data
        redirect: 'follow',
      };

      fetch('/api/create-userfields', requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.status) {
            toast.success('User Field Created');
            let mappedData = [];
            if (promptData?.prompts && promptData?.prompts?.length > 0) {
              mappedData = promptData?.prompts.map((innerPrompt) => {
                if (innerPrompt) {
                  if (innerPrompt.userFields) {
                    const field = {
                      ...data.updatedUserFields[0],
                      instruction: '',
                      placeholder: data.updatedUserFields[0].instruction,
                    };
                    innerPrompt.userFields.push(field);
                    return innerPrompt;
                  }
                  return innerPrompt;
                }
              });
            }
            setPromptData({
              ...promptData,
              userFields: userFields,
              prompts: mappedData,
            });
            // handleRegenerate();
          }
        })

        .catch((error) => {
          console.log('error', error);
          toast.error('Something went wrong');
        });
    }
  };

  // systemPrompt
  const [openSystemEditModal, setOpenSystemEditModal] = useState(false);
  const handleSystemPrompt = (data) => {
    setPromptData({
      ...promptData,
      systemPrompt: data,
    });
  };

  // New Prompt
  const handleSaveNewPrompt = (event) => {
    let newId =
      promptData?.prompts?.length > 0 ? findMaxId(promptData?.prompts) + 1 : 1;
    event.id = newId;
    event.userFields = [];
    if (promptData?.userFields && promptData?.userFields.length > 0) {
      event.userFields = promptData?.userFields.map((item) => {
        return {
          ...item,
          instruction: '',
          placeholder: item.instruction,
        };
      });
    }

    let updatedPrompts = [...(promptData.prompts || []), event];

    setPromptData((prevPromptData) => {
      const updatedData = {
        ...prevPromptData,
        prompts: updatedPrompts,
      };

      handleSave('Prompt added', updatedData);
      return updatedData;
    });
  };

  //  Edit Prompt
  const [editModel, setEditModel] = useState({});

  // delete prompt
  const handleRemovePrompt = (id) => {
    // Filter out the prompt with the matching ID from the prompts state
    const newPromptsArray = promptData.prompts.filter((item) => item.id !== id);
    // Update the state with the filtered prompts array
    setPromptData({
      ...promptData,
      prompts: newPromptsArray,
    });
  };

  // Test
  const [open, setOpen] = useState(false);
  const [testOpen, setTestOpen] = useState(false);
  const [testData, setTestData] = useState('');
  const [testIndex, setTextIndex] = useState();

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }

    const updatedPrompts = [...promptData.prompts];
    const [movedPrompt] = updatedPrompts.splice(source.index, 1);
    updatedPrompts.splice(destination.index, 0, movedPrompt);

    // Update orderIndex based on new order
    updatedPrompts.forEach((prompt, index) => {
      prompt.orderIndex = index;
    });

    console.log("updatedPrompts++++drag+++++", updatedPrompts)
    setPromptData({
      ...promptData,
      prompts: updatedPrompts,
    });


    saveUpdatedPrompts(updatedPrompts); // Function to save changes to backend
  };

  const saveUpdatedPrompts = (updatedPrompts) => {

    const data = {
      Prompts: updatedPrompts.map((prompt) => ({
        ...prompt,
      })),
      templateId: id,
    };
    console.log("data++++++++++++++++", data)

    fetch('/api/updatePromptOrder', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {

          console.log('Prompts updated successfully:', result);
        } else {

          console.error('Error updating prompts:', result.message);
        }
      })
      .catch((error) => {
        console.error('Error updating prompts:', error);

      });
  };



  return (
    <>
      <div className="quartely_review_page">
        <div className="quartely_head">
          <div className="chat_link process_temp1">
            <Link
              to={!select ? `/view-template/${id}` : `${id}/create-chatflow`}
              className="link-with-icon"
            >
              {promptData.name}
            </Link>
          </div>
        </div>

        <div className="language_modal">
          <div className="language_dropdown">
            <div className="language_head">
              <h3>Language model</h3>
            </div>

            <div className="language_dropdown_info">
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <Select
                  value={promptData.languageModel}
                  onChange={(event) => {
                    setPromptData({
                      ...promptData,
                      languageModel: event.target.value,
                    });
                  }}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem value="gpt-4o">gpt-4o</MenuItem>
                  <MenuItem value="gpt-4-turbo-preview">
                    gpt-4-turbo-preview
                  </MenuItem>
                  <MenuItem value="gpt-4">gpt-4</MenuItem>
                  <MenuItem value="gpt-3.5-turbo">gpt-3.5-turbo</MenuItem>
                  <MenuItem value="gpt-3.5-turbo-16k">
                    gpt-3.5-turbo-16k
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="language_slider">
            <div className="language_head">
              <h3>Temperature</h3>
            </div>
            <div className="slider_box">
              <Box sx={{ width: 300 }}>
                <div className="slider_box slider_box1">
                  <Box sx={{ width: 300 }}>
                    <Slider
                      aria-label="Custom marks"
                      value={promptData.temperature}
                      step={0.1}
                      min={0}
                      max={1}
                      valueLabelDisplay="auto"
                      ThumbComponent={ArrowDownwardIcon}
                      onChange={(event, newValue) => {
                        setPromptData({
                          ...promptData,
                          temperature: newValue,
                        });
                      }}
                    />
                  </Box>
                </div>
              </Box>
            </div>
          </div>
        </div>
        <div
          className="catgry"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <div className="language_head">
            <h3>User Data Input</h3>
          </div>
          <div className="Add-Icon">
            <Button
              onClick={() => {
                setFieldModal(true);
                setFieldObject('');
              }}
            >
              <AddCircleIcon />
            </Button>
          </div>
        </div>
        {promptData.userFields &&
          promptData.userFields.length > 0 &&
          promptData.userFields.map((val) => {
            return (
              <div
                className="contain-text"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '8px',
                  gap: '50px',
                }}
              >
                <div
                  className="left-column"
                  onClick={() => {
                    setFieldObject(val);
                    setFieldModal(true);
                  }}
                  style={{ width: '60%' }}
                >
                  <p style={{ wordWrap: 'break-word' }}>{val?.name}</p>
                </div>
                <div className="right-column">
                  {val?.type === 1 && <p>Text Area</p>}
                  {val?.type === 2 && <p>Text Field</p>}
                  {val?.type === 3 && <p>File Upload</p>}
                  {val?.type === 4 && <p>Url</p>}
                </div>
              </div>
            );
          })}
        {openFieldModal && (
          <CustomUserInputModal
            handleRmeoveField={handleRmeoveField}
            handleUserField={handleUserField}
            open={openFieldModal}
            openData={fieldObject}
            setFieldModal={setFieldModal}
            setOpenData={setFieldObject}
          />
        )}

        <div className="system_prompt">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              padding: '20px 0px',
            }}
          >
            <div className="language_head1" style={{ flex: 1 }}>
              <h3>System Prompt</h3>
            </div>
            <div
              className="editModalContainer"
              style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '35px',
              }}
            >
              <Button onClick={() => setOpenSystemEditModal(true)}>Edit</Button>
              {openSystemEditModal && (
                <EditSystemPromptModal
                  open={true}
                  setSystemPrompt={handleSystemPrompt}
                  systemPrompt={promptData.systemPrompt}
                  setOpenSystemEditModal={setOpenSystemEditModal}
                />
              )}
            </div>
          </div>
          <div className="system_analyse1">
            <TextareaAutosize
              value={promptData.systemPrompt}
              onChange={(event) => {
                setPromptData({
                  ...promptData,
                  systemPrompt: event.target.value,
                });
              }}
              style={{
                fontFamily: "'Poppins', sans-serif",
                fontSize: '18px',
                color: '#2e2929',
                maxHeight: '100px',
                resize: 'none',
                overflowY: 'scroll',
              }}
              disabled
            />
            <a className="job-data" href="#">
              [Job Data]
            </a>
          </div>
        </div>
        {/* end system prompt */}

        <div className="system_prompt system_prompt1">
          <NewpromtModal
            handleSaveNewPrompt={handleSaveNewPrompt}
            savePrompt={handleSave}
          />
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="prompts">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {promptData.prompts &&
                    promptData.prompts.length > 0 && promptData.prompts.map((val, index) => (
                      <Draggable key={val.id.toString()} draggableId={val.id.toString()} index={index}>
                        {(provided,snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={`prompt_div ${snapshot.isDragging ? 'dragging' : ''}`}
                          >
                            <div className="prompt_quartely">
                              <div className="language_head1">
                                <h3>{val?.name}</h3>
                              </div>
                              <div className="job_data">
                                <span className="output_word">
                                  Output Length: {val?.outputLength} Words
                                </span>
                                <Button
                                  onClick={() => {
                                    setEditModel({
                                      prompt: val,
                                    });
                                  }}
                                >
                                  Edit
                                </Button>

                                <Button
                                  onClick={(event) => {
                                    setOpen(true);
                                    setTestOpen(true);
                                    setTestData(val);
                                    setTextIndex(index);
                                  }}
                                  style={{ textDecoration: 'underline' }}
                                >
                                  Test
                                </Button>
                                <DeleteModal
                                  prompt={val}
                                  handleRemovePrompt={handleRemovePrompt}
                                  id={promptData.id}
                                />
                              </div>
                            </div>
                            <div className="flexDrag">
                            <div className="system_analyse">
                              <p>{val?.prompt}</p>
                            </div>
                            <div className="drag_handle">
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {editModel?.prompt && (
            <EditModal
              prompt={editModel.prompt}
              result={promptData}
              getPromptTemplate={handleRegenerate}
              isOpen={true}
              setEditModel={setEditModel}
            />
          )}
          {testOpen && (
            <TestModal
              setOpen={setOpen}
              prompt={testData}
              userFields={promptData.userFields}
              open={open}
              result={{ promptData }}
              promptData={promptData}
              setPromptData={setPromptData}
              testIndex={testIndex}
            />
          )}
        </div>
        <div className="quarterly_buttons">
          <div className="save_cancel_btn">
            <Stack direction="row" spacing={2}>
              <Button
                variant="contained"
                onClick={() => {
                  navigate('/prompts/all');
                }}
              >
                Cancel
              </Button>
              <Button variant="contained" onClick={() => handleSave()}>
                Save
              </Button>
            </Stack>
          </div>
        </div>
      </div>
    </>
  );
}
