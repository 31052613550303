import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { TextareaAutosize } from '@mui/base';
import Input from '@mui/material/Input';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CircularProgress, TextField, Backdrop } from '@mui/material';
import Papa from 'papaparse';
import Dropzone from './Dropzone'; // Adjust the path as per your project structure
import pdfToText from 'react-pdftotext';
import { socket } from '../socket';

const BouncingDotsLoader = ({ message }) => {
  return (
    <div class="loader-container" style={{ flexDirection: 'column' }}>
      <div class="bouncing-dots">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
      </div>
      <p>{message}</p>
    </div>
  );
};
export default function CreateChatFlow() {
  const [isLoading, setLoading] = useState(false);
  const [isLoadPage, setLoadPage] = useState(false);
  const [templateId, setTemplateId] = useState('');
  const [loaderMessage, setLoaderMessage] = useState(
    'Request Sending to server..'
  );
  const [userFields, setUserFields] = useState([]);
  const [name, setName] = useState('');
  const [promptTemplateName, setPromptTemplateName] = useState('');
  const { user } = useAuth0();
  const navigate = useNavigate();
  const { template_id } = useParams();
  const [myUser, setMyUser] = useState(null); // Initialize myUser with null

  useEffect(() => {
    console.log({ user });
    setMyUser(user); // Set myUser state once user is available
    console.log({ myUser });
    if (user) {
      fetchPromptTemplates();
    }
  }, [user]);

  useEffect(() => {
    const handleDocumentCreationStatus = function (data) {
      if (!myUser) return; // Guard against myUser being null or undefined
      const { sub } = myUser;

      console.log(
        `socket callback....${isLoadPage && data.data.user_id === sub}`,
        isLoadPage,
        data.data.user_id,
        sub,
        data
      );

      if (isLoadPage && data.data.user_id === sub) {
        console.log('here..........');
        setLoaderMessage(data.data.message);
      }
    };

    // Check if the event listener is already set up
    if (!socket.hasListeners('documentCreationStatus')) {
      socket.on('documentCreationStatus', handleDocumentCreationStatus);
    }

    // Clean up the event listener when the component unmounts
    return () => {
      socket.off('documentCreationStatus', handleDocumentCreationStatus);
    };
  }, [socket, myUser, isLoadPage]);

  const fetchPromptTemplates = async () => {
    try {
      const { sub } = user;
      let myHeaders = new Headers();
      myHeaders.append('user_id', sub);

      let requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      };

      const response = await fetch(
        `/api/prompt_template/${template_id}`,
        requestOptions
      );
      const result = await response.json();

      if (result.status) {
        setTemplateId(result?.template?.promptTemplateId?._id);
        setPromptTemplateName(result?.template?.promptTemplateId?.name);

        let userField = result?.template?.userFields.map((val) => ({
          name: val?.name,
          instruction: val?.instruction,
          id: val?.id,
          type: val?.type,
        }));

        setUserFields(userField);
      }
    } catch (error) {
      console.log('Error fetching prompt templates:', error);
    }
  };

  const handleSave = async () => {
    setLoadPage(true);
    try {
      const { sub } = user;

      const missingFields = userFields.some(val => {
        if (val.type === 2) {
          // Assuming type 2 fields are text inputs that are required
          return val.instruction.trim() === '';
        } else if (val.type === 3) {
          // Assuming type 3 fields are file uploads that are required
          console.log("val.files{{",val.file)
          console.log("val.s{{",val)
          return !val.file || val.file.length === 0;
        } else if (val.type === 4) {
          // Assuming type 4 fields are URL inputs
          return val.instruction.trim() === '' || !val.instruction.startsWith('http');
        }
        return false;
      });
  
      // Check if any invalid URLs
      const invalidUrls = userFields.some(val =>
        val.type === 4 && val.instruction && !val.instruction.startsWith('http')
      );
  console.log("missingFields",missingFields)
      if (templateId === '' || name === '' || missingFields || invalidUrls) {
        if (invalidUrls) {
          toast.error('Please add a valid URL starting with http');
        } else if (missingFields) {
          toast.error('All required fields must be filled');
        } else {
          toast.error('All fields are required');
        }
        return;
      }

      let myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');

      let raw = JSON.stringify({
        promptTemplateId: templateId,
        name: name,
        user_id: sub,
        userFields: userFields,
      });

      let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
      };

      const response = await fetch('/api/chatflow', requestOptions);
      const result = await response.json();

      if (result.status) {
        toast.success('Document created successfully');
        setTimeout(() => {
          navigate('/chatflows');
        }, 1500);
      } else {
        toast.error(result?.message);
      }
    } catch (error) {
      console.log('Error saving document:', error);
    } finally {
      setLoadPage(false);
    }
  };

  const handleValChange = (event, id) => {
    let updatedFields = userFields.map((val) => {
      if (val.id === id) {
        return { ...val, instruction: event.target.value };
      }
      return val;
    });

    setUserFields(updatedFields);
  };
  const transformObjects = (array) => {
    return array.map((obj) => {
      const transformedObj = {};

      for (let key in obj) {
        if (key.includes('.')) {
          const keys = key.split('.');
          let currentLevel = transformedObj;

          for (let i = 0; i < keys.length; i++) {
            const prop = keys[i];
            if (i === keys.length - 1) {
              currentLevel[prop] = obj[key];
            } else {
              if (!currentLevel[prop]) {
                currentLevel[prop] = {};
              }
              currentLevel = currentLevel[prop];
            }
          }
        } else {
          transformedObj[key] = obj[key];
        }
      }

      return transformedObj;
    });
  };

  const handleFileRemove = (id) => {
    const updatedFields = userFields.map((val) =>
      val.id === id ? { ...val, file: undefined, fileType: undefined } : val
    );
    setUserFields(updatedFields);
  };
  

  const handleDropzoneChange = async (acceptedFiles, id) => {
    console.log("acceptedFiles---------",acceptedFiles)

    if (acceptedFiles.length === 0) return;

    const file = acceptedFiles[0];
    const reader = new FileReader();
    const fileTypes = [
      'application/pdf',
      'text/csv',
      'text/plain',
      'text/html',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation', // pptx
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
      'application/msword', // doc
      'application/json', // json
      'image/jpeg', // jpeg
      'image/jpg', // jpg
      'image/png', // png
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // xlsx
    ];
    const readAsTextPromise = new Promise((resolve, reject) => {
      reader.onload = (e) => {
        const content = e.target.result;
        if (!fileTypes.includes(file.type)) {
          toast.error(
            'Unsupported file type! Only txt, csv, and html files are allowed.'
          );
          reject(new Error('Unsupported file type'));
        }
        resolve(content);
      };

      reader.readAsArrayBuffer(file); // Read the file based on bufferType
    });

    // const readAsTextBufferPromise = new Promise((resolve, reject) => {
    //   const readerText = new FileReader();
    //   readerText.onload = (e) => {
    //     const content = e.target.result;
    //     resolve(content);
    //   };
    //   if (file.type === 'text/csv') {
    //     readerText.readAsText(file); // Read the file as text for CSV
    //   }
    // });

    try {
      let context = {};
      let updatedFields = [...userFields];
      const jsonObj = updatedFields.findIndex((field) => field.id == id);

      switch (file.type) {
        case 'text/plain':
          const buffer = await readAsTextPromise;
          const base64 = btoa(
            new Uint8Array(buffer).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ''
            )
          );
          // context.content = new TextDecoder().decode(buffer);
          // updatedFields[jsonObj].context = context;
          updatedFields[jsonObj].file = base64;
          updatedFields[jsonObj].fileType = 'text/txt';
          break;

        case 'text/html':
          const bufferHtml = await readAsTextPromise;
          const base64Html = btoa(
            new Uint8Array(bufferHtml).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ''
            )
          );
          // context.content = new TextDecoder().decode(bufferHtml);
          // updatedFields[jsonObj].context = context;
          updatedFields[jsonObj].file = base64Html;
          updatedFields[jsonObj].fileType = file.type;
          break;

        case 'application/pdf':
          // const extracted = await pdfToText(file);
          // context.content = extracted;
          const bufferPdf = await readAsTextPromise;
          const base64Pdf = btoa(
            new Uint8Array(bufferPdf).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ''
            )
          );
          // updatedFields[jsonObj].context = context;
          updatedFields[jsonObj].file = base64Pdf;
          updatedFields[jsonObj].fileType = file.type;
          break;

        case 'text/csv':
          const bufferCsv = await readAsTextPromise;
          // const bufferCsvText = await readAsTextBufferPromise;
          // Papa.parse(bufferCsvText, {
          //   header: true,
          //   complete: (results) => {
          //     context.content = transformObjects(results.data);
          //     updatedFields[jsonObj].context = context;
          //   },
          // });
          const base64Csv = btoa(
            new Uint8Array(bufferCsv).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ''
            )
          );
          updatedFields[jsonObj].file = base64Csv;
          updatedFields[jsonObj].fileType = file.type;
          break;
          case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
            const bufferPpt = await readAsTextPromise;

            const base64Ppt = btoa(
              new Uint8Array(bufferPpt).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ''
              )
            );
            updatedFields[jsonObj].file = base64Ppt;
            updatedFields[jsonObj].fileType ='text/ppt';
            break;
        
          case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            const bufferDocx = await readAsTextPromise;

            const base64Docx = btoa(
              new Uint8Array(bufferDocx).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ''
              )
            );
            updatedFields[jsonObj].file = base64Docx;
            updatedFields[jsonObj].fileType ='text/docx';
            break;
        
          case 'application/msword':
            const bufferDoc = await readAsTextPromise;

            const base64Doc = btoa(
              new Uint8Array(bufferDoc).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ''
              )
            );
            updatedFields[jsonObj].file = base64Doc;
            updatedFields[jsonObj].fileType ='text/doc';
            break;
        
          case 'application/json':
            const bufferJson = await readAsTextPromise;

            const base64Json = btoa(
              new Uint8Array(bufferJson).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ''
              )
            );
            updatedFields[jsonObj].file = base64Json;
            updatedFields[jsonObj].fileType ='text/json';
            break;
        
          case 'image/jpeg':
            const bufferJpeg = await readAsTextPromise;

            const base64Jpeg = btoa(
              new Uint8Array(bufferJpeg).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ''
              )
            );
            updatedFields[jsonObj].file = base64Jpeg;
            updatedFields[jsonObj].fileType ='image/jpeg';
            break;
          case 'image/jpg':
            const bufferJpg = await readAsTextPromise;

            const base64Jpg = btoa(
              new Uint8Array(bufferJpg).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ''
              )
            );
            updatedFields[jsonObj].file = base64Jpg;
            updatedFields[jsonObj].fileType ='image/jpg';
            break;
          case 'image/png':
            const bufferpng = await readAsTextPromise;

            const base64png = btoa(
              new Uint8Array(bufferpng).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ''
              )
            );
            updatedFields[jsonObj].file = base64png;
            updatedFields[jsonObj].fileType ='image/png';
            break;
        
          case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            const bufferxlsx = await readAsTextPromise;

            const base64xlsx = btoa(
              new Uint8Array(bufferxlsx).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ''
              )
            );
            updatedFields[jsonObj].file = base64xlsx;
            updatedFields[jsonObj].fileType ='text/xlsx';
            break;
        default:
          throw new Error('Unsupported file type');
      }

      setUserFields(updatedFields);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="chat_flows">
      <Box sx={{ flexGrow: 1 }}>
        {isLoadPage && (
          <div>
            <div
              id="loader-main"
              style={{
                color: '#fff',
                zIndex: (theme) => theme.zIndex.drawer + 1,
                opacity: 1,
                visibility: 'visible !important',
              }}
              open={isLoadPage}
            >
              <BouncingDotsLoader message={loaderMessage} />
            </div>
          </div>
        )}
      </Box>
      {/* <div>
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isLoadPage}
            >
              <CircularProgress color="inherit" />
              {loaderMessage}
            </Backdrop>
          </div> */}
      <div className="chat_haed">
        <Typography variant="h1">Create Document</Typography>
      </div>
      <div className="form_feilds">
        <Box>
          <Typography>
            <div className="chat_label_input">
              <Typography component="label">Prompt Template</Typography>
              <div className="prompt_select">
                <FormControl>
                  <Input
                    placeholder="Prompt Template"
                    disabled
                    value={promptTemplateName}
                  />
                </FormControl>
              </div>
            </div>
            <div className="chat_label_input">
              <Typography component="label">Name</Typography>
              <div className="prompt_select">
              <Input
                placeholder="Document Name"
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
              </div>
            </div>
            {userFields.map((val) => (
              <div className="chat_label_input" key={val.id}>
                <Typography component="label">{val.name}</Typography>
                {val.type === 2 ? (
              <div className="prompt_select">

                  <TextField
                    placeholder={val.instruction}
                    onChange={(event) => handleValChange(event, val.id)}
                  />
              </div>

                ) : val.type === 3 ? (
                  <Dropzone
                    onDrop={(acceptedFiles) =>
                      handleDropzoneChange(acceptedFiles, val.id)
                    }
                    onRemove={() =>
                      handleFileRemove(val.id)}
                  />
                ) : val.type === 4 ? (
              <div className="prompt_select">

                  <TextField
                    placeholder={val.instruction}
                    onChange={(event) => handleValChange(event, val.id)}
                    error={
                      val.instruction && !val.instruction.startsWith('http')
                    }
                    helperText={
                      val.instruction && !val.instruction.startsWith('http')
                        ? 'URL must start with http'
                        : ''
                    }
                  />
              </div>

                ) : (
              <div className="prompt_select">

                  <TextareaAutosize
                    placeholder={val.instruction}
                    onChange={(event) => handleValChange(event, val.id)}
                  />
              </div>

                )}
                
              </div>
            ))}

            <div className="chat_flow_delete_btn">
              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  onClick={() => navigate('/chatflows')}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={handleSave}
                  disabled={isLoading}
                >
                  {isLoading ? <CircularProgress size={24} /> : 'Save'}
                </Button>
              </Stack>
            </div>
          </Typography>
        </Box>
      </div>
    </div>
  );
}
