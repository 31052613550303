import  React, {useState} from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
export default function ProfileComponent(props) {
  const {name} = props;
  const { logout } = useAuth0();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
 const handleClick = (event) => {
  event.stopPropagation(); 
  setAnchorEl(event.currentTarget);
  event.preventDefault();
};

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
     localStorage.removeItem("userLogin");
     logout();
  }

  
  return (
    <div className="user-profile">
        <ul className="profile_icon">
  <li className="profile-icon">
    <Link className='profile-link' to="">
      <p><AccountCircleOutlinedIcon /></p>
      <Button
        id="basic-button"
        aria-controls={anchorEl ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={Boolean(anchorEl).toString()}
        onClick={handleClick}
        className="profile-name"
      >
        <div className="name-arrow-container">
          <span className="name">{name}</span>
          <p className="arrow-icon">
            <KeyboardArrowRightOutlinedIcon />
          </p>
        </div>
      </Button>
    </Link>
    <div className="logout">
    <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        style={{ left: '-114px', top: '20px' }}
      >
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </div>
  </li>
</ul>
   
    </div>
  );
}