import React from 'react'
import SidebarLayout from './layouts/SidebarLayout';
import { styled, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
// import Stack from '@mui/material/Stack';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import { Link } from 'react-router-dom';
export default function PromptsPage() {
  const chatboxdata = [
    {
      head: 'Sales Meeting',
      date: '1/1/2023',
      para1:'Template Description'
    },
    {
      head: 'Product Review',
      date: '1/1/2023',
      para1:'Template Description'
    },
    {
      head: 'NDA Review',
      date: '1/1/2023',
      para1:'Template Description'
    },
    {
      head: 'Q4/12 Financial Review',
      date: '1/1/2023',
      para1:'Template Description'
    },
    {
      head: 'Q4/12 Financial Review',
      date: '1/1/2023',
      para1:'Template Description'
    },
    {
      head: 'Flow Name',
      date: '1/1/2023',
      para1:'Template Description'
    }
  ]
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  }));

  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
  }));
  return (



    <div>


        <div className='chatflows_page'>
          <div className='chat_head'>
            <Typography variant="h1" >
            Prompt Templates
            </Typography>
          </div>
          {/* end heading */}
          <div className='search_box'>

            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <Item>
                    <div className='search_input'>
                      <Search className='serachBar'>
                        <SearchIconWrapper>
                          <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                          placeholder="Search…"
                          inputProps={{ 'aria-label': 'search' }}
                        />
                      </Search>
                    </div>
                  </Item>
                </Grid>
              </Grid>
            </Box>
          </div>
          {/* end search box */}

          <div className='chat_boxes'>

            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>

                {chatboxdata.map((data, i) => {

                  return (
                    <Grid item xs={4}>
                      <Item>
                        <div className='first_chatbox'>
                          <div className='icon'>
                            <StickyNote2OutlinedIcon />
                          </div>
                          
                          <div className='chat_heading'>
                            <div className='chat_link'>
                                <Link to="">{data.head}</Link>
                            </div>
                            <h6>{data.para1}</h6>
                           
                            <Typography  component="p">{data.date}</Typography>
                          </div>
                        </div>
                      </Item>
                    </Grid>
                  )
                })}

              </Grid>
            </Box>

          </div>
          {/* end chat box */}
        </div>
    </div>
  )
}

