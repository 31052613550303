import React, { useEffect , useState } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import userRoutes from "./routes/index";
import { useAuth0 } from "@auth0/auth0-react";
import SidebarLayout from './pages/layouts/SidebarLayout'

// import Layout from './layouts/layout'

const App = () => {
  const { isAuthenticated, loginWithRedirect, isLoading, user , getIdTokenClaims , getAccessTokenSilently  } = useAuth0();
  // console.log("isAuthenticated", isAuthenticated, isLoading ,user);
  const [loading , setLoading] = useState(true);

  if(isAuthenticated){
    localStorage.setItem("userLogin",true);
  }
  let userLoggin = localStorage.getItem("userLogin");
  
  if(!isAuthenticated && (user == null || user == undefined) && !isLoading){
     loginWithRedirect();
     return false;
  }

  if(user){
    const saveDetails = async () => {

      let token = await getAccessTokenSilently();
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", token);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch("/api/create-user", requestOptions)
      .then(response => response.json())
      .then(result => {
         
      })
      .catch(error => console.log('error', error));
    }
    saveDetails();
  }
    

  // if(!isAuthenticated && userLoggin && !isLoading){
  //    loginWithRedirect();
  //    return false;
  // }

  return (
    <BrowserRouter>
     <SidebarLayout>

      <Routes>
        {userRoutes.map((itm, idx) => {
          return <Route path={itm.path} element={itm.component} key={idx}/>;
        })}
      </Routes>
        </SidebarLayout>
    </BrowserRouter>
  );
};

export default App;
