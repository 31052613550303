import React, { useEffect, useState } from "react";
import SidebarLayout from "./layouts/SidebarLayout";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import GetAppIcon from "@mui/icons-material/GetApp";
import Input from "@mui/material/Input";
import { TextareaAutosize } from '@mui/base';
import { CircularProgress, TextField } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { toast } from "react-toastify";
import moment from "moment";
import Collapsible from 'react-collapsible';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'; 
import ExpandLessIcon from '@mui/icons-material/ExpandLess'; 

function CollapsibleText({ content }) {
  const lines = content.split('\n');
  const initialLines = lines.slice(0, 6).join('\n');
  const remainingLines = lines.slice(6).join('\n');
  const [expanded, setExpanded] = useState(false);
  return (
    <div>
      <pre style={{ padding: '10px', overflow: 'auto', whiteSpace: 'pre-wrap' ,
    border: '1px solid #1976d2',borderRadius : '5px' , background:'white', maxHeight: '300px' }}>{expanded ? content : initialLines}</pre>
      {lines.length > 5 && (
        <div className="collapse" onClick={() => setExpanded(!expanded)}>
        {expanded ? (
          <>
            <ExpandLessIcon />
          </>
        ) : (
          <>
            <ExpandMoreIcon />
          </>
        )}
      </div>
      )}
    </div>
  );
}


export default function ViewChatFlow() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { user } = useAuth0();

  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [prompTemplate, setPromptTemplate] = useState("");
  const [flowData, setFlowData] = useState("");
  const [createdDate, setCreatedDate] = useState("");
  const [status, setStatus] = useState("");
  const [result, setResult] = useState([]);
  const [userFields, setUserFields] = useState([]);
  console.log("result", result);
  useEffect(() => {
    if (id && user) {
      getChatFlow();
    }
  }, [id, user]);

  const getChatFlow = () => {
    try {
      let { sub } = user;
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("user_id", sub);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`/api/chatflow/${id}`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result?.status) {
            let chatflow = result?.chatflow;
            setTitle(chatflow?.name);
            setFlowData(chatflow?.data);
            setPromptTemplate(chatflow?.promptTemplateId?.name);
            setCreatedDate(moment(chatflow?.created_at).format("DD/MM/YYYY"));
            setStatus(chatflow?.status);
            setResult(chatflow?.result);
            setUserFields(chatflow?.userFields);
          } else {
            toast.error(result?.message);
            setTimeout(() => {
              navigate("/chatflows");
            }, 1500);
          }
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log("err", error);
    }
  };

  const [selectedTab, setSelectedTab] = useState('Results'); 
  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  const handleDownloadFlow = () => {
    try {
      let { sub } = user;
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("user_id", sub);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`/api/chatflow/download/${id}`, requestOptions)
      .then((response) => response.blob()) // Use response.blob() to get the binary data as a Blob
      .then((blob) => {
        // Create a blob URL from the response data and simulate a download
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${title}.pdf`;
        link.click();
        URL.revokeObjectURL(url);
      })
      .catch((error) => console.log("error", error));
    } catch (error) {
      console.log("err", error);
    }
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text)
    toast.success("Copied!")
  }

  return (
      <div className="chat_flows">
        <div className="chat_haed">
          <Typography variant="h1">{title}</Typography>
        </div>
        <div className="form_feilds1">
        <Box>
      <div className="tab-buttons">
        <Button
          variant={selectedTab === 'Results' ? 'contained' : 'outlined'}
          onClick={() => handleTabChange('Results')}
        >
          Results
        </Button>
        <Button
          variant={selectedTab === 'Settings' ? 'contained' : 'outlined'}
          onClick={() => handleTabChange('Settings')}
        >
          Settings
        </Button>
      </div>
      <Typography>
        <div className="light-blue">
          {selectedTab === 'Settings' && (
            <>
              <div className="chat_label_input">
                <div className="promt_label">
                  <Typography component="label">Prompt Template</Typography>
                </div>
                <div className="promt_input">
                  <Input placeholder="" value={prompTemplate} disabled />
                </div>
              </div>
              <div className="chat_label_input">
                <div className="promt_label">
                  <Typography component="label">Created</Typography>
                </div>
                <div className="promt_input1">
                  <Input disabled placeholder="" value={createdDate} />
                </div>
              </div>


              {userFields && userFields.map((val)=>{
                console.log('val',val)
                 return (
                  <div className="chat_label_input">
                  <Typography  component="label">{val?.name}</Typography>
                  {val?.type == 2 ? (
                    <div className="prompt_select">
                    <TextField  placeholder="instructions" value={val?.instruction} disabled/> 
                   </div>
                  ):(
                    <div className="prompt_select">
                    <TextareaAutosize  placeholder="instructions" value={val?.instruction} disabled/>
                   </div>
                    )}

                </div>
                 );
              })}


              <div className="chat_label_input">
                {/* <div className="promt_label">
                  <Typography component="label">Input Data</Typography>
                </div>
                <div className="para_box">
                  <Typography variant="body1">
                    <pre
                      style={{
                        padding: '10px',
                        overflow: 'auto',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      {flowData}
                    </pre>
                  </Typography>
                </div> */}
              </div>
            </>
          )}

          {selectedTab === 'Results' && (
            <>
              {status === 0 && (
                <div>
                  <span>Status: Pending</span>
                </div>
              )}
              {status === 1 && result.length > 0 && (
                <>
                  <div className="chat_label_input1">
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography component="label" style={{ marginRight: 'auto' }}>
                        Template Output
                      </Typography>
                      <p>
                        <GetAppIcon
                          onClick={handleDownloadFlow}
                          className="hand-cursor-icon"
                        />
                      </p>
                    </div>
                  </div>

                  {result.map((val) => (
                    <div class="light-blue">
                      <div className="provide_analise">
                        <div className="cipboard_div">
                          <Typography variant="h5">{val?.name}</Typography>
                          <ContentCopyIcon
                            onClick={() => {
                              handleCopy(val?.result);
                            }}
                          />
                        </div>
                        <div
                          className="analyse_box"
                          style={{ padding: '10px', overflow: 'auto' }}
                        >
                          <Typography variant="body1">
                            <pre
                              style={{
                                padding: '10px',
                                overflow: 'auto',
                                whiteSpace: 'pre-wrap',
                              }}
                            >
                              {val?.result}
                            </pre>
                          </Typography>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </>
          )}
        </div>
        <div className="chat_flow_delete_btn">
          <Button variant="contained" onClick={() => {navigate('/chatflows')}}>
            Done
          </Button>
        </div>
      </Typography>
    </Box>
        </div>
      </div>
  );

}