import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { Link, useLocation, useParams } from "react-router-dom";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import ProfileComponent from "../../components/ProfileComponent";
import { useAuth0 } from "@auth0/auth0-react";
import { handleBreakpoints } from "@mui/system";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PersonIcon from '@mui/icons-material/Person';
import CancelIcon from '@mui/icons-material/Cancel';

type Anchor = "menu";

export default function SidebarLayout({ children }) {
  const location = useLocation();
  const {category} = useParams();
  // const [allCategories, setAllCategories] = useState([]);
  const [selectedPage, setSelectedPage] = useState('');
  const [loadingStates, setLoadingStates] = useState({
    '/': false,
    '/chatflows': false,
    '/prompts/all': false,
  });



  const handleLinkClick = (path) => {
    setSelectedPage(path);
    setLoadingStates((prevState) => ({
      ...prevState,
      [path]: true,
    }));

    setTimeout(() => {
      setLoadingStates((prevState) => ({
        ...prevState,
        [path]: false,
      }));
    }, 500);
  };

  function getLink(text) {
    if (text === 'Home') {
      return '/';
    } else if (text === 'Documents') {
      return '/chatflows';
    } else if (text === 'Templates') {
      return '/prompts/all';
    }
    return '/';
  }


  // const getAllCategories = () => {
  //   let myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  //   let requestOptions = {
  //     method: "GET",
  //     headers: myHeaders,
  //     redirect: "follow",
  //   };

  //   fetch(`/api/category`, requestOptions)
  //     .then((response) => response.json())
  //     .then((result) => {
  //       if (result.status) {
  //         setAllCategories(result.categories)
  //       } 
  //     })
  //     .catch((error) => console.log("error", error));
  // }

  // useEffect(()=>{
  //   getAllCategories();
  // },[])

  const { user } = useAuth0();
  const [state, setState] = React.useState({
    top: false,
    right: false,
  });
  const [name, setName] = React.useState('');

  const handleCloseDrawer = () => {
    setState({
      top: false,
      right: false,
    })
 }
 
 const handleProfileClick = (event) => {
  // event.stopPropagation();
}

 useEffect(()=>{
  handleCloseDrawer();
},[location])

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {

        console.log('here')
        if (
          event.type === "keydown" &&
          (event.key === "Tab" || event.key === "Shift")
        ) {
          return;
        }
       
        setState({ ...state, [anchor]: open });
      };
  
  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      className="drawer-handle"
    >
      <List>
        {['Home', 'Documents', 'Templates'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton component={Link} to={getLink(text)}>
              {/* <ListItemIcon>
                {getMenuIcon(text)}
              </ListItemIcon> */}
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}

      </List>

      <Divider style={{ borderBottom: '1px solid #fff' }} />
      <div className="ProfileName">
        <ProfileComponent
          name={name}
          className={selectedPage === '/profile' ? 'selected' : ''}
        />
      </div>
    </Box>

  );
  // console.log('user',user);
  useEffect(() => {
    if (user) {
      setName(user?.nickname)
    }
  }, [user])
  return (
    <div>
      <ToastContainer />

      <div className="sidebar_layout">
        <div className="list_side">
          <ul className="icon_list_side">
            <li>
              <Link
                to="/"
                className={location.pathname === '/' ? 'selected' : ''}
              >
                <p>
                  <HomeIcon
                    style={{
                      color:
                        location.pathname === '/' &&
                          !loadingStates['/']
                          ? '#1976d2'
                          : '',
                    }}
                  />
                </p>
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/chatflows"
                className={`${location.pathname === '/chatflows' ||
                    location.pathname.startsWith('/view-chatflow/')
                    ? 'selected'
                    : ''
                  }`}
              >
                <p>
                  <ArrowCircleRightOutlinedIcon
                    style={{
                      color:
                        (location.pathname === '/chatflows' ||
                          location.pathname.startsWith('/view-chatflow/')) &&
                          !loadingStates['/chatflows']
                          ? '#1976d2'
                          : '',
                    }}
                  />
                </p>
                Documents
              </Link>
            </li>
            <li>
              <Link
                to="/prompts/all"
                className={
                  location.pathname === '/prompts/all' ||
                    location.pathname.startsWith('/view-template/')
                    ? 'selected'
                    : ''
                }
              >
                <p>
                  <DescriptionOutlinedIcon
                    style={{
                      color:
                        (location.pathname.includes('/prompts/') ||
                          location.pathname.startsWith('/view-template/')) &&
                          !loadingStates['/prompts/all']
                          ? '#fff'
                          : '',
                    }}
                  />
                </p>
                Templates
              </Link>
              <ul className="inner-li">
              {/* <li>
                <Link
                className={`${location.pathname.split('/')[2] == 'all' && 'active_link'}`}
                  to={`${location.pathname.split('/')[1] == 'select-prompt' ? '/select-prompt/all' : '/prompts/all'}`} >
                  <p>All</p>
                </Link>
              </li> */}
               {/* {allCategories && allCategories.map((val)=>{
                 let pathname = location.pathname;
                 let redirectpath = 'prompts'
                 if(pathname.includes('/prompts/')){
                   pathname = pathname.split('/')[2];
                }
                 if(pathname.includes('/select-prompt/')){
                   pathname = pathname.split('/')[2];
                   redirectpath = 'select-prompt'
                 }
                 console.log('redirect-path',redirectpath);
                 return (
                  <li>
                  <Link
                    className={`${pathname == val?._id && 'active_link'}`}
                    to={`${redirectpath == 'prompts' ? `/prompts/${val?._id}` : `/select-prompt/${val?._id}`}`} >
                    <p>{val?.name}</p>
                  </Link>
                </li>
                 )
               })} */}
            </ul>
          </li>

          </ul>

          <div className="content-container">
            {loadingStates['/'] && (
              <div className="spinner-container">
                <div className="spinner"></div>
              </div>
            )}
            {loadingStates['/chatflows'] && (
              <div className="spinner-container">
                <div className="spinner"></div>
              </div>
            )}
            {loadingStates['/prompts/all'] && (
              <div className="spinner-container">
                <div className="spinner"></div>
              </div>
            )}
          </div>
          <div style={{ maxHeight: '200px', marginBottom: '30px' }} className="maindiv">
            <div className="profile">
              <ProfileComponent name={name} />
              <div className="menu_list">
                {["right"].map((anchor,index) => (
                  <React.Fragment key={index}>
                    <Button onClick={toggleDrawer(anchor, true)}>
                      <MenuOutlinedIcon />
                    </Button>
                    <Drawer
                      anchor={anchor}
                      open={state[anchor]}
                    >
                      <div> 
                      <Button onClick={handleCloseDrawer} style={{ float: 'right' }}>
                          <CancelIcon />
                      </Button>
                        {list(anchor)}
                      </div>
                    </Drawer>
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="content_side">
          <main>{children}</main>
        </div>
      </div>
    </div>
  );
}
