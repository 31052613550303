import HomePage from '../pages/HomePage'
import ChatFlows from '../pages/ChatFlows'
import PromptsPage from '../pages/PromptsPage'
import CreateChatFlow from '../pages/CreateChatFlow'
import NewFlow from '../pages/NewFlow'
import FlowDetail from '../pages/FlowDetail'
import SlidePage from '../pages/SlidePage'
import SelectPromptTemplate from '../pages/SelectPromptTemplate'
import ViewChatFlow from '../pages/ViewChatFlow'
import ViewTemplate from '../pages/ViewTemplate'
import SystemTemplate from '../pages/SystemTemplate'
const userRoutes = [
    {path: '/', component: <HomePage /> },
    {path: '/chatflows', component: <ChatFlows /> },
    {path: '/prompts/:category', component: <PromptsPage /> },
    {path: '/select-prompt/:category/:template_id/create-chatflow', component: <CreateChatFlow /> },
    {path: '/newflow', component: <NewFlow /> },
    {path: '/flowdetail', component: <FlowDetail /> },
    {path: '/slidepage', component: <SlidePage /> },
    {path: '/select-prompt/:category', component: <SelectPromptTemplate /> },
    {path: '/view-chatflow/:id', component: <ViewChatFlow /> },
    {path: '/view-template/:id', component: <ViewTemplate /> },
    {path: '/system-template/:id', component: <SystemTemplate /> }

]

export default userRoutes