import React, { useEffect, useState } from "react";
import SidebarLayout from "./layouts/SidebarLayout";
import Link from "@mui/material/Link";
import TemplateModal from "../components/TemplateModal";
import Typography from "@mui/material/Typography";
import ReactPlayer from "react-player";
import { useAuth0 } from "@auth0/auth0-react";

export default function HomePage() {
  const [promptTemplate, setPromptTemplate] = useState(1);
  const [chatFlow, setChatFlow] = useState(1);
  const { user } = useAuth0();

  useEffect(() => {
    if (user) {
      fetchCount();
    }
  }, [user]);

  const fetchCount = async () => {
    try {
      let { sub } = user;

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        user_id: sub,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch("/api/get-count", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.status) {
            setPromptTemplate(result?.prompTempCount);
            setChatFlow(result?.chatflowCount);
          }
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log("err", error);
    }
  };

  return (
    <div>
        <div className="home_page">
          <div className="page_description">
            <Typography variant="h1">Welcome to PromptSpark! 🚀</Typography>
            <div className="skelton">
              <Typography component="p">
                {/* Welcome to PromptSpark! 🚀 */}

                  Illuminate your content creation journey with the power of ChatGPT-inspired technology. From analyzing intricate reports to summarizing LinkedIn profiles, news pages, and more, PromptSpark is your torchbearer.<br/>
                 </Typography>
                 <Typography component="p">
                  <b>Why choose PromptSpark?</b><br/>
                  </Typography>
                  <Typography component="p" className="points">
                  <b>1. Automated Efficiency:</b> Say goodbye to repetitive ChatGPT processes! With PromptSpark's templates, you can achieve consistent, high-quality results every time, saving both effort and time.<br/>
                  </Typography>
                  <Typography component="p" className="points">
                  <b>2. Versatility:</b> Whether you’re using our curated system-provided templates or crafting bespoke ones, PromptSpark adapts to your needs.<br/>
                  </Typography>
                  <Typography component="p"className="points">
                  <b>3. Analytics Power:</b> Dive deep into data analysis, extract pivotal insights, and translate them into compelling narratives.<br/>
                  </Typography>
                  <Typography component="p" className="points">
                  <b>4. Document Mastery:</b> Process detailed documents or simplify complex reports with unparalleled ease.<br/>
                  </Typography>
                  <Typography component="p" className="points">
                  <b>5. Summaries at a Glance:</b> Distill the essence of a news page, LinkedIn profile, or any other content source swiftly.<br/>
                  </Typography>
                  <Typography component="p"className="points">
                  <b>6. Simplicity & Efficiency:</b> Choose your template, input your specifics, and behold as your custom content emerges brilliantly. Retrieve it instantly on-screen or in a refined PDF format.<br/>
                  </Typography>
                  <Typography component="p">
                  <b>Ready to ignite? 🔥</b><br/>
                  </Typography>
                  <Typography component="p" className="points">
                    <span className="bullet-point">&#8226;</span> To start using system templates, <b>click on "Documents"</b>.<br />
                  </Typography>
                  <Typography component="p" className="points">
                    <span className="bullet-point">&#8226;</span> To define and craft your unique templates, <b>click on "Templates"</b>.<br />
                  </Typography>
                 
                  <Typography component="p">
                  Experience the new age of content creation - where automation meets precision, only with PromptSpark!
              </Typography>
              <div className="divide"></div>
              <Typography component="p">
              Ignite smarter, more efficient processes, every time! 🌟📄🖋️
              </Typography>
            </div>
            {promptTemplate == 0 && (
              <div className="process_link">
                <Link href="/prompts/all" underline="none">
                  Create your first Prompt Template
                </Link>
              </div>
            )}
            {chatFlow == 0 && (
              <div className="process_link">
                <Link href="/chatflows" underline="none">
                  Process Your First Document
                </Link>
              </div>
            )}
          </div>
          <div className="chatflow_use">
            <div className="chatflows_box">
              <ReactPlayer
                controls
                url="https://youtu.be/RfeBfBp4u-k"
              />
            </div>

            {/* <div className='icons'>
                            <ul>
                                <li><Link href="#"> <PlayCircleIcon /></Link></li>
                                <li className='volume_list'> <VolumeDown />
                                    <Slider aria-label="Volume" value={value} onChange={handleChange} />
                                    <VolumeUp /></li>
                                <li><FullscreenIcon /></li>
                            </ul>
                        </div> */}
          </div>
        </div>
    </div>
  );
}
