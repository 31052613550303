import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import Stack from "@mui/material/Stack";

import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import CircularProgress from '@mui/material/CircularProgress';
export default function ShareTemplate(props) {
    const { userId, id, TempTitle: title, setRestart, restart } = props;
    const API_BASEURL = process.env.API_BASEURL
        ? process.env.API_BASEURL
        : "http://localhost:3001";

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 850,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
    };


    const [loadData, setLoading] = useState(true)
    const [dataSubmitting, setDataSubmitting] = useState(false)

    const [isNotify, setisNotify] = useState(true);
    const [message, setMessage] = useState('');
    const [SelectedTags, setSelectedTags] = useState([]);
    const [Tags, setTags] = useState("");
    const [users, setusers] = useState([])
    const getAllUser = () => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(`/api/get-users/${userId}/${id}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status) {
                    setLoading(false)
                    if (result?.data?.sharedUsers?.length > 0) {

                        const { templateAdmin, sharedUsers } = result.data
                        let arr = [
                            {
                                id: templateAdmin[0]._id,
                                name: templateAdmin[0].name,
                                email: templateAdmin[0].email,
                                type: "Owner"
                            }
                        ]
                        sharedUsers.map((item) => {
                            arr.push({
                                id: item._id,
                                name: item?.userData?.length > 0 ? item.userData[0].name : "User",
                                email: item.email,
                                type: "User"
                            })
                        })
                        setusers(arr)

                    }
                    // let arr = result.data?.map((item)=> item.email)
                } else {
                    toast.error(result?.message);
                }
            })
            .catch((error) => console.log("error", error));
    }

    useEffect(() => {
        // if (open) {
        getAllUser()


        // }
    }, [userId, id]);



    const handleSave = () => {
        setDataSubmitting(true)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var form_data = new FormData();
        form_data.append("isNotify", isNotify);
        form_data.append("message", message);
        form_data.append("email", JSON.stringify(SelectedTags));
        form_data.append("user_id", userId);
        form_data.append("id", id);

        if (SelectedTags.length > 0) {
            fetch(`/api/share-template-to-user/${id}`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    isNotify,
                    message,
                    "email": SelectedTags,
                    userId
                })
            }).then((response) => response.json()).then((result) => {
                if (result.status === true) {
                    setDataSubmitting(false)
                    props.handleShare()

                    toast.success("Template sent successfully");
                    setRestart(!restart)
                } else {
                    setDataSubmitting(false)
                    toast.error(result.error);
                }
            }).catch((error) => {
                setDataSubmitting(false)
                console.log("error", error)
                toast.error("Something went wrong please try again");
            })
        } else {
            toast.error("Email is mendatory");
        }

    };

    const handleNotifyPeople = (event) => {
        setisNotify(!isNotify)
    }
    const handleMessage = (event) => {
        setMessage(event.target.value)
    }
    const [emailInput, setEmail] = useState("")
    const keyPressFunction = (event) => {
        // event.preventDefault();
        if (event.which === 13) {
            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailInput)) {
                event.preventDefault();
                let tagindex = SelectedTags.indexOf(emailInput)
                let newtags = SelectedTags.splice(tagindex, 1);
                setSelectedTags(SelectedTags)
                toast.error("Invalid Email");
            } else {
                setEmail("")
            }
        }
    }

    const handletags = (event, tags) => {
        event.preventDefault();
        let lasttag = tags[tags.length - 1]
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(lasttag)) {
            event.preventDefault();
            let tagindex = tags.indexOf(lasttag)
            let newtags = tags.splice(tagindex, 1);
            setSelectedTags(newtags)
        }
    }


    const handleRemoveUser = (event, id) => {

        fetch(`/api/delete-shared-template`, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                _id: id
            })
        }).then((response) => response.json()).then((result) => {

            if (result.status === true) {
                props.handleShare()
                toast.success("User removed successfully");
                setRestart(!restart)
            } else {
                toast.error("Something went wrong please try again");
            }
        }).catch((error) => {
            console.log("error", error)
            toast.error("Something went wrong please try again");
        })
    }
    return (
        <>
            <div className="">
                {/* <Button className={id && 'update_prompt_temp'} onClick={handleOpen}>{buttonTitle}</Button> */}
                <Modal
                    open={props.open}
                    onClose={props.handleShare}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography
                            id="modal-modal-title"
                            variant="h4"
                            component="h2"
                            className={`modal_head ${id ? 'update_prompt_temp' : ''}`}
                        >
                            Share "{title}"
                        </Typography>

                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            <div className="label_input" >
                                <Autocomplete style={{ width: '100%', minWidth: 'auto', border: '1px solid #1976d2 !important', padding: '0px', fontSize: '16px', borderRadius: '4px', fontFamily: 'Poppins' }}
                                    multiple
                                    id="tags-filled"
                                    options={[]}
                                    freeSolo
                                    disableClearable
                                    onChange={(event, newTags) => {
                                        setSelectedTags(newTags);
                                        handletags(event, newTags)

                                    }}
                                    inputValue={Tags}
                                    onInputChange={(event, newTags) => {
                                        setTags(newTags);
                                        // handletags(event,newTags)
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            style={{ minWidth: 'auto' }}
                                            {...params}
                                            placeholder="Enter an email address to share"
                                            onKeyUp={keyPressFunction}
                                            onChange={(event, newTags) => {
                                                setEmail(event.target.value)
                                            }}
                                        />
                                    )}
                                />

                            </div>




                            {users?.length > 0 && loadData === false && SelectedTags.length === 0 ?
                                <div>
                                    <h2 style={{ marginBottom: '12px' }}>People with access</h2>
                                    <Box maxWidth="lg" className="">
                                        <Grid container spacing={3} id="user-template-share-list" style={{ maxHeight: '140px', overflowY: 'scroll' }}>
                                            {users.map((item, index) => {
                                                return (
                                                    <Grid item xs={12} key={index}>
                                                        <div className="main-user" style={{ margin: '0px 24px 0px 24px', display: 'flex' }}>
                                                            <div className="text" style={{ flex: 'auto', maxWidth: '550px' }}>
                                                                <i>
                                                                    <p className="user-name">
                                                                        {item.name} {item.type === "Owner" ? "(you)" : ''}
                                                                    </p>
                                                                </i>
                                                                <i>
                                                                    <p>
                                                                        {item.email}
                                                                    </p>
                                                                </i>
                                                            </div>
                                                            {item.type === "Owner" ? <i>
                                                                <p>
                                                                    {item.type}
                                                                </p>
                                                            </i> :
                                                                <div style={{ display: 'flex' }}>
                                                                    <i>
                                                                        <p style={{ marginTop: '8px', width: '100px' }}>
                                                                            User
                                                                        </p>
                                                                    </i>
                                                                    <Button onClick={((event) => handleRemoveUser(event, item.id))}>
                                                                        <DeleteIcon />
                                                                    </Button>
                                                                </div>}
                                                        </div>
                                                    </Grid>
                                                )
                                            })}

                                        </Grid>
                                    </Box>
                                </div>
                                : loadData === false ?
                                    <>
                                        <div className="label_input">
                                            <FormGroup>
                                                <FormControlLabel control={<Checkbox defaultChecked onChange={handleNotifyPeople} />} label="Notify People" />
                                            </FormGroup>
                                        </div>

                                        <div className="label_input">
                                            <TextField
                                                id="text-fields"
                                                placeholder="Message"
                                                multiline
                                                rows={5}
                                                maxRows={7}
                                                onChange={handleMessage}
                                            />
                                        </div>
                                    </> : ''
                            }
                            {loadData === true ? <Box sx={{ display: 'flex' }} style={{ justifyContent: 'center' }} >
                                <CircularProgress />
                            </Box> : ''}
                            <div className="flow_delete_btn" style={{ marginTop: '40px' }}>
                                <Stack direction="row" spacing={2}>
                                    <Button onClick={props.handleShare} variant="contained">
                                        {SelectedTags?.length === 0 ? 'Close' : 'Cancel'}
                                    </Button>
                                    {SelectedTags?.length > 0 ?
                                        <>

                                            {dataSubmitting === true ?
                                                <CircularProgress /> :
                                                <Button
                                                    variant="contained"
                                                    onClick={handleSave}
                                                >
                                                    Add user
                                                </Button>
                                            }
                                        </>


                                        : ""}
                                </Stack>
                            </div>
                        </Typography>
                    </Box>
                </Modal>
            </div>
        </>
    );
}
