import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Input from "@mui/material/Input";
import { TextareaAutosize } from '@mui/base';
import Stack from "@mui/material/Stack";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function NewTemplate(props) {
  const navigate = useNavigate();
  const { name, id , TempTitle , userId,restart , setRestart , category } =
    props;

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 850,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const [buttonTitle, setButtonTitle] = React.useState(
    name ? name : "New Template"
  );
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = React.useState(TempTitle ? TempTitle : "");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  
  useEffect(()=>{
    setTitle(TempTitle ? TempTitle : "")
  },[TempTitle])

  const handleSave = () => {
    if (title == "") {
      toast.error("Name is required!");
      return false;
    }

    if (id) {
      updateFlow();
    } 
  };
  const handleNameChange = (event) => {
    const maxCharacter = 20; 

    if (event.target.value.length <= maxCharacter) {
      setTitle(event.target.value);
    }
  };
  
  const updateFlow = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("name", title);
    urlencoded.append("user_id", userId);

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    fetch(`/api/chatflow/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          toast.success("Document updated Successfully");
          setOpen(false);
          setRestart(!restart);
        } else {
          toast.error(result?.message);
        }
      })
      .catch((error) => console.log("error", error));
  };
  return (
    <>
      <div className="">
        <Button className={id && 'update_prompt_temp'} onClick={handleOpen}>{buttonTitle}</Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              class="modal_head"
              variant="h4"
              component="h2"
            >
              Edit Chatflow
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <div className="label_input">
                <Typography component="label">Name</Typography>
                <Input 
                  onChange={handleNameChange}
                  value={title}
                  placeholder="Template name"
                />
              </div>
              <div className="flow_delete_btn">
                <Stack direction="row" spacing={2}>
                  <Button onClick={handleClose} variant="contained">
                    Close
                  </Button>
                  <Button variant="contained" onClick={handleSave}>
                    Update
                  </Button>
                </Stack>
              </div>
            </Typography>
          </Box>
        </Modal>
      </div>
    </>
  );
}
