import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Stack from '@mui/material/Stack';
import { toast } from 'react-toastify';

export default function EditSystemPromptModal(props) {
  const { setSystemPrompt, systemPrompt, setOpenSystemEditModal } = props;
  const { promptId } = useParams();

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 850,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(props.open);
  }, []);
  const [TextModelprompt, setTextModelPrompt] = useState(systemPrompt);
  console.log('TextModelprompt', TextModelprompt);
  useEffect(() => {
    // Fetch prompt details using promptId when component mounts
    fetchPromptDetails();
  }, [promptId]);

  useEffect(() => {
    // Update local state when systemPrompt prop changes
    setTextModelPrompt(systemPrompt);
  }, [systemPrompt]);

  const fetchPromptDetails = async () => {
    try {
      // Fetch prompt details based on promptId
      const response = await fetch(`/getPromptDetails/${promptId}`);
      if (!response.ok) {
        // throw new Error('Failed to fetch prompt details');
      }
      const data = await response.json();
      setTextModelPrompt(data.prompt);
    } catch (error) {
      console.error('Error fetching prompt details:', error);
      //   toast.error('Failed to fetch prompt details');
    }
  };

  const handleSave = async () => {
    try {
      const pathSegments = window.location.pathname.split('/');
      const promptId = pathSegments[pathSegments.length - 1];
      console.log('promptId', promptId);
      const response = await fetch('/api/updateSystemPrompt', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          promptTemplateId: promptId,
          systemPrompt: TextModelprompt,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setSystemPrompt(TextModelprompt);
        toast.success(data.message);
        setOpen(false);
        setOpenSystemEditModal(false);
      } else {
        // toast.error(data.message || 'Failed to update system prompt');
      }
    } catch (error) {
      console.error('Error updating system prompt:', error);
      //   toast.error('Failed to update system prompt');
    }
  };

  return (
    <>
      <div className="create_template2">
        <Modal
          open={open}
          onClose={() => {
            setOpen(false);
            setOpenSystemEditModal(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              className="modal_head"
              variant="h4"
              component="h2"
            >
              Edit System Prompt
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <div className="label_input">
                <Typography component="label">System Prompt</Typography>
                <TextareaAutosize
                  value={TextModelprompt}
                  onChange={(event) => {
                    setTextModelPrompt(event.target.value);
                  }}
                />
              </div>
              <div className="flow_delete_btn">
                <Stack direction="row" spacing={2}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button variant="contained" onClick={handleSave}>
                    Save
                  </Button>
                </Stack>
              </div>
            </Typography>
          </Box>
        </Modal>
      </div>
    </>
  );
}
