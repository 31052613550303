import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { toast } from 'react-toastify';
import { useAuth0 } from "@auth0/auth0-react";

export default function SystemTemplate(props) {
  const {  select } = props;
  const [temperature, setTemperature] = useState(1);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [restart, setRestart] = useState(false);
  const [languageModel, setLanguageModel] = useState("");
  const [systemPrompt, setSystemPrompt] = useState("");
  const [prompts, setPrompts] = useState([]);
  const [userFields, setUserFields] = useState([]);

  const navigate = useNavigate();
  const { id } = useParams();
  const { user } = useAuth0();
  useEffect(() => {
    if (id && user) {
      getPromptTemplate();
    }
  }, [id,user,restart]);

 
  


  const getPromptTemplate = () => {
    try {
      let { sub } = user;
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("user_id", sub);

      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`/api/prompt/${id}`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result?.status) {
            let promptData = result?.promptData;
            setTemperature(promptData?.temperature == null ? 1 : promptData?.temperature);
            setTitle(promptData?.name);
            setDescription(promptData?.description);
            setLanguageModel(promptData?.languageModel);
            setSystemPrompt(promptData?.systemPrompt);
            setPrompts(
              promptData?.prompts !== undefined ? promptData?.prompts : []
            );
            setUserFields(
              promptData?.userFields !== undefined ? promptData?.userFields : []
            );
          }
          else{
             toast.error(result?.message)
             setTimeout(()=>{
                navigate('/prompts/all')
             },1500)
          }
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log("err", error);
    }
  };






  return (
    <>
        <div className="quartely_review_page">
          <div className="quartely_head">
          <div className="chat_link process_temp1">
            <Link
              to={!select ? `/view-template/${id}` : `${id}/create-chatflow`}
              className="link-with-icon"
            >
              {title}
            </Link>
          </div>
          </div>

          <div className="language_modal">
            <div className="language_dropdown">
              <div className="language_head">
                <h3>Language model: </h3>
              </div>
                  
              <div className="language_dropdown_info">
                  <Typography><strong>{languageModel}</strong></Typography>
              </div>
            </div>
            <div className="language_slider">
              <div className="language_head">
                <h3>Temperature:</h3>
              </div>
              <div className="slider_box">
              <Typography><strong>{temperature}</strong></Typography>
              </div>
            </div>
          </div>
          {/* end langiage model */}
          <div className="catgry" style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                <div className="language_head">
                    <h3>User Data Input</h3>
                    </div>

                  </div>
            {userFields && userFields.length > 0 && userFields.map((val)=>{
               return (
                <div className="contain-text" style={{ display: 'flex', alignItems: 'center', padding: '8px', gap: '50px' }}>
                  <div className="left-column" style={{ width: "170px" }}>
                    <p style={{ wordWrap: 'break-word' }}>{val?.name}</p>
                  </div>
                  <div className="right-column">
                    <p>{val?.type === 2 ? 'Text Field' : 'Text Box'}</p>
                  </div>
              </div>            
               )
            })}
          <div className="system_prompt">
            <div className="language_head1">
              <h3>System Prompt</h3>
            </div>
            <div className="system_analyse1 system_prompt_temp">
              <p>{systemPrompt}</p>
              <a className="job-data" href="#">[Job Data]</a>
            </div>
          </div>
          {/* end system prompt */}

          <div className="system_prompt system_prompt1">
            {prompts &&
              prompts.length > 0 &&
              prompts.map((val , index) => {
                console.log('val', val)
                return (
                  <div className="prompt_div" key={index}>
                    <div className="prompt_quartely">
                      <div className="language_head1">
                        <h3>{val?.name}</h3>
                      </div>
                      <div className="job_data">
                         <span>Output Length: {val?.outputLength} Words</span>
                        {/* <Link to="">Edit</Link> */}
                      </div>
                    </div>
                    <div className="system_analyse">
                      <p>
                        {val?.prompt}
                      </p>
                      {/* <div className='job_data'>
                <Link to="">Job Data</Link>
              </div> */}
                    </div>
                  </div>
                );
              })}
          </div>

          <div className="quarterly_buttons">
            <div className="save_cancel_btn">
              <Stack direction="row" spacing={2}>
                <Button variant="contained" onClick={()=>{navigate('/prompts/all')}}>Done</Button>
              </Stack>
            </div>
          </div>

          {/* end prompt analyse Quartely */}
        </div>
    </>
  );
}
