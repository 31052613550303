import React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

const DeleteModal = ({ prompt, handleRemovePrompt }) => {

    const handleRemove = async () => {
        // Display SweetAlert confirmation
        const result = await Swal.fire({
            title: `Confirm this Prompt Deletion`,
            text: `Are you sure you want to delete this Prompt?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel'
        });

        if (result.isConfirmed) {
            try {
                const response = await fetch(`/api/prompt/${prompt.id}`, {
                    method: 'DELETE'
                });
                const data = await response.json();
                if (data.status) {
                    // Call handleRemovePrompt to remove the prompt from the frontend
                    handleRemovePrompt(prompt.id);
                    // Show toast notification
                    toast.success('Prompt Deleted Successfully');
                } else {
                    console.error('Error:', data.message);
                    // Show toast notification for error
                    toast.error('Failed to delete prompt');
                }
            } catch (error) {
                console.error('Error:', error);
                // Show toast notification for error
                toast.error('An error occurred while deleting the prompt');
            }
        }
    };

    return (
        <div className="create_template2">
            <Button onClick={handleRemove}>Delete</Button>
        </div>
    );
};

export default DeleteModal;
