import React, { useState } from "react";
import { Link } from "react-router-dom";
import EditTemplate from "../components/EditTemplate";
import Button from "@mui/material/Button";
// import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Swal from 'sweetalert2';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import NewTemplate from "./NewTemplate";
import ShareTemplate from "./ShareTemplate";
import { toast } from "react-toastify";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import GroupIcon from '@mui/icons-material/Group';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import "../App.css"

export default function PromptBox(props) {
  const {isSystem, title, description, date, id, userId, setRestart, restart, select, isLoading, setIsLoading, system_template, category, categories, totalShares, tabId, ownerName } = props;

  const iconStyle = {
    cursor: 'pointer',
  };
  const shouldShowDiv = !system_template && !select;

  const handleDelete = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await deletePromptTemplate();
        if (res !== true) {
          toast.error(res);
          return false;
        }
        console.log('res', res)
        Swal.fire(
          'Deleted!',
          'Your prompt template has been deleted.',
          'success'
        )
        setRestart(!restart);
      }
    })
  }

  const deletePromptTemplate = () => {
    return new Promise((resolve, reject) => {
      var requestOptions = {
        method: 'DELETE',
        redirect: 'follow'
      };

      fetch(`/api/prompt_template/${id}`, requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.status) {
            resolve(true);
          }
          else {
            resolve(result.message);
          }
        })
        .catch(error => reject(true));
      // reject(true);
    });
  }

  const truncateText = (text, maxCharacters) => {
    if (text.length > maxCharacters) {
      return text.substr(0, maxCharacters) + '...';
    }
    return text;
  };

  const maxTitle = 50;
  const maxDescription = 200;
  const truncatedTitle = truncateText(title, maxTitle);
  const truncatedDescription = truncateText(description, maxDescription);

  const handleCopy = () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("user_id", userId);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
      };
      setIsLoading(true);
      fetch(`/api/duplicate_template/${id}`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setIsLoading(false);
          if (result.status) {
            toast.success("Template Copied.");
            setRestart(!restart);
          } else {
            toast.error(result?.message);
          }
        })
        .catch((error) => console.log("error", error));
    } catch (error) {

    }
  }
  const [tempShare, setTempShare] = useState(false);
  const handleShare = () => {
    setTempShare(!tempShare)
  }
  return (

    <Grid item xs={12} md={4}>
      <div className="first_chatbox">
        <div className="icon" style={{ display: 'flex' }}>
          <TextSnippetOutlinedIcon style={{ flexShrink: '0' }} />

          {shouldShowDiv && totalShares > 0 ?
            <div style={{ flexGrow: '1', textAlign: 'end' }}>
              <GroupIcon style={{ fill: 'grey', fontSize: '25px', cursor: 'pointer' }} onClick={handleShare} />
            </div> : ""}

        </div>

        <div className="chat_heading">
          <div className="TitleDes">
            <div className="chat_link process_temp">

            {!ownerName ? 
              <Link to={!system_template ? !select ? `/view-template/${id}` : `${id}/create-chatflow` : `/system-template/${id}`} className={system_template && `system_template_div`}>{truncatedTitle}</Link>:
              <Link to={props.displayCopy &&  props.displayCopy.isDisplay ==true ?`/system-template/${id}`: `${id}/create-chatflow`} className={system_template && `system_template_div`}>{truncatedTitle}</Link>
              }

            </div>
            <Typography variant="h6" style={{fontSize: '13.5px','overflow': 'hidden','display': '-webkit-box','-webkit-line-clamp': '3',lineClamp: '3','-webkit-box-orient': 'vertical'}}>{truncatedDescription}</Typography>
            {props.message && 
              <>
              {/* <br/> */}
              <p style={{marginTop :'15px',fontSize: '13.5px',  'overflow': 'hidden','display': '-webkit-box','-webkit-line-clamp': '3',lineClamp: '3', '-webkit-box-orient': 'vertical'}}>{props.message}</p>
              </>
            
            }
          </div>
          {/*  */}
          <div className="handleCopy">

            <Typography component="p">{date}</Typography>

          </div>
          {/* {!tabId && ownerName ? */}
          {
            ownerName || isSystem ?

              <div className="template-owner-details" >
                <div style={{ display: 'flex' }}>
                  <AccountCircleIcon id="share-owner-icon" style={{ fill: 'grey !important', fontSize: '25px' }} />
                  <Typography component="p" style={{ marginTop: '0px' }}>{ownerName?ownerName: "PromptSpark"}</Typography>
                  {/* <p style={{ marginTop: '-7px' }}>{ownerName}</p> */}
                </div>
              </div>
              : ""
          }
        
          {shouldShowDiv && !ownerName && (
            <div className="icn" style={{ display: 'flex', alignItems: 'center', marginTop: '75px', marginRight: '-20px', marginLeft: '-22px', marginTop: '10px' }}>
              <div className="share-tempsIcon">
                {!select && tabId && !ownerName &&
                  <Button onClick={handleShare} style={{ marginRight: '-21px' }}>

                    <PersonAddAltIcon style={{ fill: 'grey', fontSize: '25px' }} />
                  </Button>

                }
                {tempShare === true ?
                  <ShareTemplate
                    categories={categories}
                    id={id}
                    open={tempShare}
                    handleShare={handleShare}
                    TempTitle={title}
                    TempDescription={description}
                    setRestart={setRestart}
                    restart={restart}
                    userId={userId}
                    categoryList={category}
                  />
                  : null
                }
              </div>
              <div className="dltIcon">
                <Button
                  startIcon={<DeleteIcon style={{ fill: 'grey', fontSize: '25px' }} />}
                  onClick={handleDelete}
                  style={{ marginRight: '20px' }}
                >
                </Button>
              </div>
              <div className="handleIcon">
                <Button>
                  <ContentCopyIcon onClick={handleCopy} style={{ fill: 'grey', fontSize: '25px' }} />
                </Button>
              </div>
              <div className="edit" style={{marginTop:'1px'}}>
                {!select &&
                  <NewTemplate
                    categories={categories}
                    id={id}
                    name={<EditOutlinedIcon style={{ fill: 'grey', fontSize: '25px' }} />}
                    TempTitle={title}
                    TempDescription={description}
                    setRestart={setRestart}
                    restart={restart}
                    userId={userId}
                    categoryList={category}
                  />
                }
              </div>

            </div>
          )}
          {ownerName && props.displayCopy &&  props.displayCopy.isDisplay ==true && <div className="icn" style={{ display: 'flex' }}>

            <div className="handleIcon" style={{marginTop: '0px',marginLeft:'-20px' }}>
              <Button>
                <ContentCopyIcon onClick={handleCopy} style={{ fill: 'grey', fontSize: '25px' }} />
              </Button>
            </div>

          </div>
          }

          {system_template && (
            <div className="icn" style={{ display: 'flex' }}>

              <div className="handleIcon" style={{marginTop: '17px',marginLeft:'-20px' }}>
                <Button>
                  <ContentCopyIcon onClick={handleCopy} style={{ fill: 'grey', fontSize: '25px' }} />
                </Button>
              </div>

            </div>
          )}
        </div>
      </div>
    </Grid>
  );
}
