import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Input from '@mui/material/Input';
import { TextareaAutosize } from '@mui/base';
import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { toast } from 'react-toastify';

export default function CustomUserInputModal(props) {
  const {
    // handleSaveUserField,
    openData,
    handleUserField,
    setOpenData,
    handleRmeoveField,
    userFieldsOpen,
    open,
    setFieldModal,
    setIsFIledOpen,
  } = props;

  const [name, setName] = React.useState('');
  const [instruction, setInstruction] = React.useState('');
  const [type, setType] = React.useState('');
  const [id, setId] = React.useState('');
  useEffect(() => {
    setName(openData?.name);
    setInstruction(openData?.instruction);
    setType(openData?.type);
    setId(openData?.id);
    console.log('Hiting constructor.....');
  }, [openData]);

  const handleClose = () => {
    setFieldModal(false);
  };

  const handleChange = (event) => {
    setType(event.target.value);
  };

  const handleSendModalData = () => {
    if (name.trim() === '' || type === '' || instruction.trim() === '') {
      toast.error('All fields are required!');
      return;
    }

    const userDataField = {
      name,
      type,
      instruction,
      id,
    };

    if (openData) {
      handleUserField(userDataField, 'update');
    } else {
      handleUserField(userDataField, 'create');
      // handleSaveUserField
    }

    setFieldModal(false);
  };

  const getTitleText = () => {
    return openData ? 'Edit User Data Field' : 'Add User Data Field';
  };

  return (
    <>
      <div className="create_template2">
        <Modal
          className="Button-modal"
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box>
            <Typography id="modal-modal-title" variant="h4" component="h2">
              {getTitleText()}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <div className="label_input">
                <Typography component="label">Name</Typography>
                <Input
                  placeholder="Enter Field Name"
                  value={name}
                  onChange={(event) => {
                    setName(event.target.value);
                  }}
                />
              </div>
              <div className="label_input">
                <Typography component="label">Type</Typography>
                <Select
                  className="user-select"
                  value={type}
                  onChange={handleChange}
                >
                  <MenuItem value={''}></MenuItem>
                  <MenuItem value={1}>Text Area</MenuItem>
                  <MenuItem value={2}>Text Field</MenuItem>
                  <MenuItem value={3}>File Upload</MenuItem>
                  <MenuItem value={4}>URL (https://)</MenuItem>
                </Select>
              </div>
              <div className="label_input">
                <Typography component="label">Instructions</Typography>
                <TextareaAutosize
                  placeholder="Instructions"
                  value={instruction}
                  onChange={(event) => {
                    setInstruction(event.target.value);
                  }}
                />
              </div>
              <div className="flow_delete_btn">
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="flex-start"
                  marginRight="370px"
                >
                  {openData?.name && (
                    <Button
                      className="remove-userfield btn-text-left"
                      onClick={(event) => {
                        handleRmeoveField(event, id);
                        handleClose();
                      }}
                      variant="contained"
                      color="error"
                    >
                      Delete
                    </Button>
                  )}
                </Stack>

                <Stack direction="row" spacing={2} justifyContent="flex-end">
                  <Button onClick={handleClose} variant="contained">
                    Cancel
                  </Button>
                  <Button variant="contained" onClick={handleSendModalData}>
                    {openData ? 'Update' : 'Create'}
                  </Button>
                </Stack>
              </div>
            </Typography>
          </Box>
        </Modal>
      </div>
    </>
  );
}
