import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";


const PromptTestOutputModal = ({
  setShowOutputModal,
  prompt,
  isResultString,
  openPrompt,
  setOpenPrompt,
}) => {


  const handleClose = () => {
    setOpenPrompt(false);
    setShowOutputModal(false);
  };

  console.log("isResultString++++++++ ", { isResultString });

  return (
    <Modal
      open={openPrompt} // Use open state to control modal visibility
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          border: "2px solid #000",
          maxHeight: "700px",
          width: "800px",
          overflowY: "auto",
          boxShadow: 24,
          p: 4,
        }}
      >


          <div class="light-blue">
            <div className="provide_analise">
              {/* <div className="cipboard_div">
                                    <Typography variant="h5">{userFields?.instruction}</Typography>
                                    <ContentCopyIcon
                                        onClick={() => {
                                            handleCopy(userFields?.result);
                                        }}
                                    />
                                </div> */}
              <Typography
                id="modal-modal-title"
                variant="h4"
                component="h2"
                sx={{
                  color: "#1976d2",
                  marginBottom: "35px",
                  fontSize: "20px",
                }}
              >
                Test: {prompt?.name}
              </Typography>
              <Typography
                id="modal-modal-title"
                variant="body1"
                component="label"
                sx={{ fontWeight: "600", marginBottom: "15px" }}
              >
                System Response
              </Typography>
              <div
                className="analyse_box"
                style={{ padding: "10px", overflow: "auto" }}
              >
                <Typography variant="body1">
                  <pre
                    style={{
                      padding: "10px",
                      overflow: "auto",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {isResultString
                      ? isResultString
                      : ""}
                  </pre>
                </Typography>
              </div>
            </div>
          </div>
     
        

        <div className="flow_delete_btn" style={{ gap: "15px" }}>
          <Button variant="contained" onClick={handleClose}>
            Done
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default PromptTestOutputModal;
