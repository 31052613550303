import React, { useState, useEffect } from "react";
import { styled, alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { Link, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import moment from "moment";
import { CircularProgress } from "@mui/material";
import ChartTable from "../components/ChartTable";
import { socket } from "../socket";

export default function ChatFlows() {

  const navigate = useNavigate();
  const [allDataLoaded, setAllDataLoaded] = useState(false);
  const [allChatFlows, setAllChatFlows] = useState([]);
  const [chatFlowData, setChatFlowData] = useState([]);
  const [processIds, setProcessIds] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [restart, setRestart] = useState(false);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [pagination, setPagination] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [myOrder, setDataOrder] = React.useState("desc");
  const [columnName, setcolumnName] = React.useState("created_at");
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('date');
  const [realTimeUpdate, setRealUpdate] = useState(false)


  useEffect(() => {

    setTimeout(() => {
      const fakeChatFlowData = [
      ];
      setIsLoading(false);
    }, 1000);
  });
  const [promptTemplateCount, setPromptTemplateCount] = useState(1);
  const [searchField, setSearchField] = useState("");
  const API_BASEURL = process.env.API_BASEURL
    ? process.env.API_BASEURL
    : "http://localhost:3001";

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  }));

  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "200ch",
        "&:focus": {
          width: "200ch",
        },
      },
    },
  }));

  const { user } = useAuth0();

  const [userId, setUserId] = useState("");
  useEffect(() => {
    console.log({myOrder, columnName})
    if (user) {
      fetchAllChatFlows();
    }
    // fetchAllChatFlows();
  }, [user, restart, page, rowsPerPage, searchField, myOrder, columnName]);

  useEffect(() => {
    if (chatFlowData && chatFlowData.length > 0) {
      if (chatFlowData && chatFlowData.length > 0) {
        chatFlowData.forEach((val) => {
          let newDate = val.date.split('/')
          val.date = newDate[1] + "/" + newDate[0] + "/" + newDate[2]
          if (val && val.status !== 1 && val.id) {
            setProcessIds((prevProcessIds) => [...prevProcessIds, val.id]);
          }
        });
      }
    }
  }, [chatFlowData]);

  const fetchAllChatFlows = async () => {
    try {
      setAllChatFlows([])
      setChatFlowData([])
      let { sub } = user;
      setUserId(sub);
      var myHeaders = new Headers();
      myHeaders.append("user_id", sub);
      // {orderBy:'desc',column:'date'}
      myHeaders.append("orderBy", myOrder);
      myHeaders.append("column", columnName);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      await fetch(`/api/get_chatflow?pageNumber=${page}&limit=${rowsPerPage}&search=${searchField}`, requestOptions)
        .then((response) => response.json())
        .then(async (result) => {
          if (result.status) {
            let tempData = [];
            if (result.chatflows.length > 0) {
              setPagination(result.result)

              await Promise.all(
                result.chatflows.map((val) => {
                  let cat = [];
                  val?.promptTemplateId?.category?.map((cate) => {
                    cat.push(cate?.name);
                  })
                  // console.log('cat',cat)
                  let tempObj = {
                    id: val?._id,
                    title: val?.name,
                    // title: val?.name[0].toUpperCase() + val?.name.slice(1),
                    status: val?.status,
                    date: moment(val?.created_at).format("DD/MM/YYYY"),
                    templateName: val?.promptTemplateName,
                    category: cat.join(',')
                  };
                  tempData.push(tempObj);
                })
              );
            }
            setPromptTemplateCount(result?.templateCount)
            setAllChatFlows(tempData)
            setChatFlowData(tempData);
          }
          // setLoading(false);
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log("err", error);
    }
  };



  const handleSearch = (event) => {
    let query = event.target.value;


    setSearchField(event.target.value);
  };

  const handleRequestSort = (event, property) => {
    console.log({mainproperty:property})
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');

    setOrderBy(property);
    let column = property === "status" ? "status" : property === "title" ? "name" : property === "templateName" ? "promptTemplateName" : property === "date" ? "created_at" :
    console.log({column})
      setcolumnName(column);
    setDataOrder(isAsc ? 'desc' : 'asc')
  }



    socket.on("getStatus", function (data) {
      if(chatFlowData.length > 0){
        let array = chatFlowData;
        let filteredArr = array.map((item) => {
          if (item.id === data.data._id) {
            console.log(item.id === data.data._id)
            item.status =1;
          }
          return item;
        })
        setAllChatFlows(filteredArr)
        setChatFlowData(filteredArr);
      }
    })
  return (
    <div>
      <div className="chatflows_page">
        <div className="chat_head">
          <h1>Documents</h1>
        </div>
        {/* end heading */}

        <div className="search_box">
          <div className="search_feild">
            <Search>
              <SearchIconWrapper>
                <SearchIcon style={{ marginTop: '0' }} />
              </SearchIconWrapper>
              <StyledInputBase
                value={searchField}
                onChange={handleSearch}
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
                autoFocus
              />
            </Search>
          </div>
          {promptTemplateCount == 1 &&
            <div className="flow_delete_btn ">
              <Link to="/select-prompt/all">New Document</Link>
            </div>
          }
        </div>
        {/* end search box */}

        <Box sx={{ flexGrow: 1 }}>
          {isLoading ? (
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '100vh',
            }}>
              <CircularProgress />
            </div>
          ) : (
            <ChartTable realTimeUpdate={realTimeUpdate} chatFlowData={chatFlowData} setPage={setPage} setRestart={setRestart} restart={restart} pagination={pagination} page={page} rowsPerPage={rowsPerPage} setIsLoading={setIsLoading} setRowsPerPage={setRowsPerPage} handleRequestSort={handleRequestSort} order={myOrder} orderBy={orderBy} />
          )}
        </Box>
        {/* end chat box */}
        {/* {chatFlowData.length == 0 && ( */}
        {!loading && allChatFlows.length == 0 && promptTemplateCount == 1 && (
          <div className="create_template_btn">
            <Link to="/select-prompt/all" underline="none">
              Create Your First Document
            </Link>
          </div>
        )}
        {!loading && allChatFlows.length == 0 && promptTemplateCount == 0 && (
          <div className="create_template_btn">
            <Link to="/prompts/all" underline="none">
              Create Your First Prompt Template
            </Link>
          </div>
        )}
        {/* )} */}
      </div>
    </div>
  );
}
